<template>
    <div :class="classes">
        {{ educationalLevelLetter }}
    </div>
</template>

<script>
export default {
    name: "AppEducationalLevelReport",
    props: {
        level: {
            type: [String, Number],
            default: 1,
            validator: value => {
                value = parseInt(value);
                return 0 < value && value < 6;
            }
        }
    },
    computed: {
        classes() {
            return ["level", `level--report-${this.educationalLevelLetter}`];
        },
        educationalLevelLetter() {
            const educationalLevels = {
                1: "i",
                2: "ii",
                3: "iii",
                4: "iv",
                5: "v"
            };
            return educationalLevels[this.level];
        }
    }
};
</script>

<style lang="sass">
.level
    display: inline-flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    padding: $spacing-xsmall

    @include font-size(14px, 1)
    font-weight: bold
    text-transform: uppercase
    text-align-last: center
    text-align: center

.level--report-v,
.level--report-iv,
.level--report-iii,
.level--report-ii,
.level--report-i
    font-family: $serif-font-family

.level--report-v
    color: $color-level-o

.level--report-iv
    color: $color-level-z

.level--report-iii
    color: $color-level-v

.level--report-ii
    color: $color-level-g

.level--report-i
    color: $color-level-zg

.level--fill
    border-radius: $global-border-radius-rounded

    color: #fff

    &.level--report-v
        background-color: $color-level-o

    &.level--report-iv
        background-color: $color-level-z

    &.level--report-iii
        background-color: $color-level-v

    &.level--report-ii
        background-color: $color-level-g

    &.level--report-i
        background-color: $color-level-zg

.level--outline
    border:
        radius: $global-border-radius-rounded
        width: 2px
        style: solid

    &.level--report-v
        border-color: $color-level-o

    &.level--report-iv
        border-color: $color-level-z

    &.level--report-iii
        border-color: $color-level-v

    &.level--report-ii
        border-color: $color-level-g

    &.level--report-i
        border-color: $color-level-zg
</style>
