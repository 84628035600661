<template>
    <TheLayout class="layout--with-sidebar">
        <aside class="layout-side">
            <AppLayoutInner>
                <AppSection variant="filter">
                    <AppHeader class="section-header">
                        <AppTitle v-translate as="h3" class="section__title">
                            Inzicht
                        </AppTitle>
                    </AppHeader>

                    <AppFormFilter>
                        <ul class="form-filter__list">
                            <li
                                v-for="insightType in insightTypes"
                                :key="insightType.name"
                                class="form-filter__item"
                            >
                                <div class="form-filter__label">
                                    <AppInputCheckbox
                                        v-model="filterValueTypes"
                                        class="form-filter__input"
                                        hide-details
                                        :value="insightType.value"
                                    >
                                        <template #label>
                                            <AppBadge
                                                class="form-filter__badge"
                                                :variant="insightType.value"
                                            >
                                                {{ insightType.text }}
                                            </AppBadge>
                                        </template>
                                    </AppInputCheckbox>
                                </div>
                            </li>
                        </ul>
                    </AppFormFilter>

                    <hr />

                    <AppFormFilter>
                        <ul class="form-filter__list">
                            <li class="form-filter__item">
                                <div class="form-filter__label">
                                    <AppInputCheckbox
                                        v-model="filterValueEncouraging"
                                        class="form-filter__input"
                                        hide-details
                                        :value="true"
                                    >
                                        <template #label>
                                            <AppBadge
                                                v-translate
                                                class="form-filter__badge"
                                            >
                                                Bevorderend
                                            </AppBadge>
                                        </template>
                                    </AppInputCheckbox>
                                </div>
                            </li>
                            <li class="form-filter__item">
                                <div class="form-filter__label">
                                    <AppInputCheckbox
                                        v-model="filterValueEncouraging"
                                        class="form-filter__input"
                                        hide-details
                                        :value="false"
                                    >
                                        <template #label>
                                            <AppBadge
                                                v-translate
                                                class="form-filter__badge"
                                            >
                                                Belemmerend
                                            </AppBadge>
                                        </template>
                                    </AppInputCheckbox>
                                </div>
                            </li>
                        </ul>
                    </AppFormFilter>

                    <hr />

                    <AppFormFilter>
                        <ul class="form-filter__list">
                            <li class="form-filter__item">
                                <div class="form-filter__label">
                                    <AppInputCheckbox
                                        v-model="filterValueImportant"
                                        class="form-filter__input"
                                        hide-details
                                    >
                                        <span
                                            slot="label"
                                            class="form-filter__value"
                                        >
                                            <AppIcon
                                                color="red"
                                                class="u-margin-right-small"
                                                name="flag"
                                            />
                                            <!-- prettier-ignore -->
                                            <translate>Belangrijk</translate>
                                        </span>
                                    </AppInputCheckbox>
                                </div>
                            </li>
                        </ul>
                    </AppFormFilter>

                    <hr />

                    <AppFormFilter>
                        <ul class="form-filter__list">
                            <li class="form-filter__item">
                                <div class="form-filter__label">
                                    <AppInputSelect
                                        v-model="filterValueSubject"
                                        allow-empty
                                        :label="$gettext('Vak')"
                                        :options="allAvailableSubjects"
                                        options-label="name"
                                        options-track-by="id"
                                    >
                                        <!-- prettier-ignore -->
                                        <span slot="noOptions" v-translate>Geen vak(ken) gevonden.</span>
                                        <!-- prettier-ignore -->
                                        <span slot="noResult" v-translate>Geen vak(ken) gevonden.</span>
                                    </AppInputSelect>
                                </div>
                            </li>
                        </ul>
                    </AppFormFilter>

                    <AppFormFilter>
                        <ul class="form-filter__list">
                            <li
                                v-for="group in allGroups"
                                :key="group.id"
                                class="form-filter__item"
                            >
                                <div class="form-filter__label">
                                    <AppInputCheckbox
                                        v-model="filterValueGroups"
                                        class="form-filter__input"
                                        hide-details
                                        :value="group.id"
                                    >
                                        <template #label>
                                            <translate
                                                :translate-params="{
                                                    name: group.name
                                                }"
                                            >
                                                Groep %{name}
                                            </translate>
                                            <span class="u-margin-left-tiny">
                                                | {{ group.year }}
                                            </span>
                                        </template>
                                    </AppInputCheckbox>
                                </div>
                            </li>
                        </ul>
                    </AppFormFilter>

                    <hr />
                </AppSection>
            </AppLayoutInner>
        </aside>

        <AppLayoutMain layout-variant="background">
            <template v-if="allInsights.length > 0">
                <AppLayoutInner>
                    <AppWrapper variant="medium pull-left">
                        <div class="d-flex u-margin-bottom">
                            <AppInsightPdfButton
                                v-model="pdfExportId"
                                class="u-margin-left-auto"
                                @click="generatePdfExport"
                            />
                        </div>

                        <!-- prettier-ignore -->
                        <template
                            v-for="(
                                [year, insights], index) in allInsightsGroupedByYear"
                        >
                            <AppHeader
                                :key="year"
                                :class="[
                                    'insight-overview__header',
                                    {
                                        'u-margin-top-large': index > 0
                                    }
                                ]"
                            >
                                <AppTitle>{{ year }}</AppTitle>
                            </AppHeader>

                            <AppInsight
                                v-for="insight in insights"
                                :key="insight.id"
                                :insight="insight"
                            />
                        </template>

                        <AppLoader
                            v-if="loading"
                            :text="$gettext('Inzichten worden geladen')"
                            class="loader--large u-margin-left-huge u-margin-bottom"
                        />
                    </AppWrapper>
                </AppLayoutInner>
            </template>
            <template v-else-if="!loading">
                <AppLayoutInner variant="centered">
                    <AppOverviewPlaceholder variant="small">
                        <AppTitle
                            v-translate
                            class="overview-placeholder__title"
                        >
                            Geen inzichten!
                        </AppTitle>
                        <p v-translate>
                            Er zijn geen inzichten gevonden. Pas uw filter aan
                            of voeg een eerste inzicht toe!
                        </p>
                        <template slot="button">
                            <AppButton
                                variant="insight"
                                @click="
                                    openModal({
                                        groupId: student
                                            ? student.currentGroup.id
                                            : null
                                    })
                                "
                            >
                                <AppIcon name="plus" variant="tiny" />
                            </AppButton>
                        </template>
                    </AppOverviewPlaceholder>
                </AppLayoutInner>
            </template>
        </AppLayoutMain>
    </TheLayout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import AppBadge from "@/components/AppBadge";
import AppButton from "@/components/AppButton";
import AppFormFilter from "@/components/AppFormFilter.vue";
import AppHeader from "@/components/AppHeader";
import AppIcon from "@/components/AppIcon";
import AppInputCheckbox from "@/components/AppInputCheckbox";
import AppInputSelect from "@/components/AppInputSelect";
import AppInsight from "@/components/AppInsight";
import AppInsightPdfButton from "@/components/AppInsightPdfButton";
import AppLayoutInner from "@/components/AppLayoutInner";
import AppLayoutMain from "@/components/AppLayoutMain";
import AppLoader from "@/components/AppLoader";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppSection from "@/components/AppSection";
import AppTitle from "@/components/AppTitle";
import AppWrapper from "@/components/AppWrapper";
import TheLayout from "@/components/TheLayoutDefault";
import { cloneDeep } from "lodash";
import { consoleError } from "@/console";
import gql from "graphql-tag";

const insightFields = `
    id
    insightId
    type
    typeDisplay
    absenceType
    absenceTypeDisplay
    absenceDurationDisplay
    title
    insight
    goal
    method
    date
    important
    attachment {
        filename
        url
    }
    encouraging
    evaluation
    dateSpecific
    dateWeekdays
    educationPlanSubject {
        id
        name
        parent {
            id
            name
        }
    }
    educationalLevelFrom
    educationalLevelTo
    educationalLevelSubject {
        id
        name
    }
    educationalLevelGrade {
        grade
    }
    subjects {
        edges {
            node {
                id
                name
            }
        }
    }
    created
    modified
    expired
`;

const insightEvaluationFields = `
    ${insightFields}
    evaluationDate
`;

export default {
    name: "InsightOverview",
    components: {
        AppFormFilter,
        AppTitle,
        AppInsightPdfButton,
        AppWrapper,
        AppLayoutMain,
        AppSection,
        AppHeader,
        AppInputSelect,
        AppLayoutInner,
        AppButton,
        AppOverviewPlaceholder,
        AppInsight,
        AppIcon,
        AppLoader,
        AppBadge,
        AppInputCheckbox,
        TheLayout
    },
    props: {
        studentId: {
            type: String,
            required: true
        },
        subject: {
            type: String,
            default: null
        },
        types: {
            type: Array,
            default: () => [],
            validator: function(value) {
                return value.every(
                    x =>
                        [
                            "appointment",
                            "event",
                            "concern",
                            "contact_moment",
                            "education_plan",
                            "diagnosis",
                            "absence"
                        ].indexOf(x) !== -1
                );
            }
        },
        groups: {
            type: Array,
            default: () => []
        },
        encouraging: {
            type: Array,
            default: () => [],
            validator: function(value) {
                return value.every(x => typeof x === "boolean");
            }
        },
        important: Boolean
    },
    data() {
        return {
            allGroups: [],
            allInsights: [],
            allSubjects: [],
            student: null,
            endCursor: "",
            hasNextPage: false,
            loading: 0,
            pdfExportId: null
        };
    },
    computed: {
        ...mapGetters("insightmodal", [
            "createdInsights",
            "updatedInsights",
            "expiredInsights",
            "deletedInsights"
        ]),
        ...mapGetters("online", ["onLine"]),
        insightTypes() {
            return [
                {
                    value: "appointment",
                    text: this.$gettext("Afspraak")
                },
                {
                    value: "event",
                    text: this.$gettext("Gebeurtenis")
                },
                {
                    value: "concern",
                    text: this.$gettext("Aandachtspunt")
                },
                {
                    value: "contact_moment",
                    text: this.$gettext("Contactmoment")
                },
                {
                    value: "education_plan",
                    text: this.$gettext("Ontwikkeling")
                },
                {
                    value: "diagnosis",
                    text: this.$gettext("Diagnose")
                },
                {
                    value: "absence",
                    text: this.$gettext("Absentie")
                }
            ];
        },
        filterValueTypes: {
            get() {
                return this.types;
            },
            set(newValue) {
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        types: newValue
                    }
                });
            }
        },
        filterValueEncouraging: {
            get() {
                return this.encouraging;
            },
            set(newValue) {
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        encouraging: newValue
                    }
                });
            }
        },
        filterValueImportant: {
            get() {
                return this.important;
            },
            set(newValue) {
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        important: newValue
                    }
                });
            }
        },
        filterValueSubject: {
            get() {
                if (this.allAvailableSubjects.length && this.subject) {
                    return this.allAvailableSubjects.find(
                        x => x.id === this.subject
                    );
                }

                // else, show empty item as placeholder
                return this.allAvailableSubjects.find(x => x.id === null);
            },
            set(newValue) {
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        subject:
                            newValue && newValue.id != null
                                ? newValue.id
                                : undefined
                    }
                });
            }
        },
        filterValueGroups: {
            get() {
                return this.groups;
            },
            set(newValue) {
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        groups: newValue
                    }
                });
            }
        },
        allAvailableSubjects() {
            return [
                { id: null, name: this.$gettext("Toon alle vakken") },
                ...this.allSubjects
            ];
        },
        allInsightsGroupedByYear() {
            // Create list of tuples
            // [
            //      [year, [...insights]],
            //      [year, [...insights]],
            // ]
            return this.allInsights.reduce((acc, insight) => {
                const year = this.formatYear(
                    atob(insight.id).split(":")[0] === "InsightExpiredProxy"
                        ? insight.expired
                        : insight.date
                );
                if (!acc.length || acc[acc.length - 1][0] !== year) {
                    acc.push([year, []]);
                }
                acc[acc.length - 1][1].push(insight);
                return acc;
            }, []);
        }
    },
    apollo: {
        allGroups: {
            query: gql`
                query allGroups($studentId: ID!) {
                    allGroups(studentId: $studentId) {
                        edges {
                            node {
                                id
                                name
                                year
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    studentId: this.studentId
                };
            },
            update(data) {
                if (data.allGroups) {
                    return data.allGroups.edges.map(x => x.node);
                } else {
                    return this.allGroups;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van de groepen."
                    ),
                    type: "error"
                });
            }
        },
        allInsights: {
            query: gql`
                query(
                    $studentId: ID!
                    $subjectId: ID
                    $first: Int!
                    $after: String
                    $types: [String]
                    $groups: [ID]
                    $encouraging: [Boolean]
                    $important: Boolean
                ) {
                    allInsights(
                        studentId: $studentId
                        subjectId: $subjectId
                        first: $first
                        after: $after
                        insightType: $types
                        groupIds: $groups
                        encouraging: $encouraging
                        important: $important
                        subjectIncludeChildren: true
                    ) {
                        pageInfo {
                            hasNextPage
                            endCursor
                        }
                        edges {
                            node {
                                ... on Insight {
                                    ${insightFields}
                                }
                                ... on InsightExpiredProxy {
                                    ${insightFields}
                                }
                                ... on InsightEvaluationProxy {
                                    ${insightEvaluationFields}
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    studentId: this.studentId,
                    first: 10,
                    after: "",
                    types: this.filterValueTypes,
                    groups: this.filterValueGroups,
                    encouraging: this.filterValueEncouraging,
                    important: this.filterValueImportant,
                    subjectId: this.filterValueSubject
                        ? this.filterValueSubject.id
                        : null
                };
            },
            update(data) {
                if (data.allInsights) {
                    this.hasNextPage = data.allInsights.pageInfo.hasNextPage;
                    this.endCursor = data.allInsights.pageInfo.endCursor;

                    return data.allInsights.edges.map(x => {
                        const node = cloneDeep(x.node);
                        node.type = node.type.toLowerCase();
                        node.subjects = node.subjects.edges.map(x => x.node);
                        return node;
                    });
                } else {
                    return this.allInsights;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van inzichten."
                    ),
                    type: "error"
                });
            }
        },
        allSubjects: {
            query: gql`
                query {
                    allSubjects(isSubSubject: false) {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            update(data) {
                if (data.allSubjects) {
                    return data.allSubjects.edges.map(x => x.node);
                } else {
                    return this.allSubjects;
                }
            }
        },
        student: {
            query: gql`
                query($id: ID!) {
                    student(id: $id) {
                        id
                        currentGroup {
                            id
                        }
                    }
                }
            `,
            variables() {
                return {
                    id: this.studentId
                };
            },
            update(data) {
                if (data.student) {
                    return data.student;
                } else {
                    return this.student;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van de leerling."
                    ),
                    type: "error"
                });
            }
        }
    },
    watch: {
        createdInsights() {
            this.refetchInsights();
        },
        updatedInsights() {
            this.refetchInsights();
        },
        expiredInsights() {
            this.refetchInsights();
        },
        deletedInsights() {
            this.refetchInsights();
        }
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    mounted() {
        this.handleScroll();
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        ...mapMutations("insightmodal", ["openModal"]),
        handleScroll() {
            if (!this.hasNextPage) return;
            const endOfPage =
                window.innerHeight + window.scrollY >=
                document.body.offsetHeight;
            endOfPage && this.showMore();
        },
        refetchInsights() {
            this.$apollo.queries.allInsights.refetch();
            window.scrollTo(0, 0);
        },
        showMore() {
            if (this.loading || !this.onLine) return;
            this.$apollo.queries.allInsights.fetchMore({
                variables: {
                    studentId: this.studentId,
                    first: 10,
                    after: this.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const allInsights = fetchMoreResult.allInsights;

                    this.hasNextPage = allInsights.pageInfo.hasNextPage;
                    this.endCursor = allInsights.pageInfo.endCursor;

                    return {
                        allInsights: {
                            __typename: previousResult.allInsights.__typename,
                            edges: [
                                ...previousResult.allInsights.edges,
                                ...allInsights.edges
                            ],
                            pageInfo: allInsights.pageInfo
                        }
                    };
                }
            });
        },
        generatePdfExport() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation createInsightExportTaskMutation(
                            $studentId: ID!
                            $groupId: ID
                            $subjectId: ID
                            $types: [String]
                            $groupIds: [ID]
                            $encouraging: [Boolean]
                            $important: Boolean
                            $notExpired: Boolean
                            $date: Date
                        ) {
                            createInsightExportTask(
                                studentId: $studentId
                                groupId: $groupId
                                subjectId: $subjectId
                                insightType: $types
                                groupIds: $groupIds
                                encouraging: $encouraging
                                important: $important
                                notExpired: $notExpired
                                date: $date
                            ) {
                                exportId
                            }
                        }
                    `,
                    variables: {
                        studentId: this.studentId,
                        groupId: this.groupId,
                        subjectId: this.filterValueSubject
                            ? this.filterValueSubject.id
                            : null,
                        types: this.filterValueTypes,
                        groupIds: this.filterValueGroups,
                        encouraging: this.filterValueEncouraging,
                        important: this.filterValueImportant,
                        notExpired: false,
                        date: null
                    }
                })
                .then(data => {
                    this.pdfExportId =
                        data.data.createInsightExportTask.exportId;
                })
                .catch(err => {
                    consoleError(err);
                    this.showMessage({
                        message: this.$gettext(
                            "Er is een fout opgetreden tijdens het exporteren, probeer het a.u.b. opnieuw."
                        ),
                        type: "error"
                    });
                });
        },
        formatYear(date) {
            return this.$moment(date).year();
        }
    }
};
</script>
<style lang="sass">
.insight-overview__header
    +mq-tablet
        margin-left: 82px
</style>
