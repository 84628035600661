var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"pagination"},[_c('ul',{staticClass:"pagination-list"},[_c('li',{staticClass:"pagination__item"},[_c('AppButton',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"pagination__button",attrs:{"variant":"link","type":"button","disabled":_vm.isInFirstPage},on:{"click":function($event){return _vm.updatePagination({ page: _vm.pagination.page - 1 })}}},[_vm._v(" Vorige ")])],1),_vm._l((_vm.pages),function(page,index){return _c('li',{key:index,staticClass:"pagination__item"},[_c('AppButton',{class:[
                    'pagination__button',
                    {
                        'pagination__button--active': _vm.isPageActive(
                            page.name
                        )
                    }
                ],attrs:{"type":"button","disabled":page.isDisabled,"variant":"link"},on:{"click":function($event){return _vm.updatePagination({ page: page.name })}}},[_vm._v(" "+_vm._s(page.name)+" ")])],1)}),_c('li',{staticClass:"pagination__item"},[_c('AppButton',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"pagination__button",attrs:{"variant":"link","type":"button","disabled":_vm.isInLastPage},on:{"click":function($event){return _vm.updatePagination({ page: _vm.pagination.page + 1 })}}},[_vm._v(" Volgende ")])],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }