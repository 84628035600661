<template>
    <AppMenuItem :class="antlerClass()">
        <slot />
    </AppMenuItem>
</template>

<script>
import AppMenuItem from "@/components/AppMenuItem";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppMenuSideItem",
    components: { AppMenuItem },
    setup() {
        const { antlerClass } = antlerComponent("nav-side__item");

        return {
            antlerClass
        };
    }
});
</script>
