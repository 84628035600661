var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TheLayout',{staticClass:"layout--with-sidebar"},[_c('aside',{staticClass:"layout-side"},[_c('AppLayoutInner',[_c('AppSection',{attrs:{"variant":"side-menu"}},[_c('AppHeader',{staticClass:"section-header"},[_c('AppTitle',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"section-header__title"},[_vm._v(" Onderwijsniveau ")])],1),_c('AppMenuSide',[_c('ul',{staticClass:"nav-menu__list"},_vm._l((_vm.allEducationalLevels),function(level){return _c('AppMenuSideItem',{key:level.id},[_c('AppMenuSideLink',{attrs:{"to":{
                                    name: 'student_level_detail',
                                    params: {
                                        year: level.year,
                                        grade: level.grade.grade
                                    }
                                },"exact-active-class":"nav-side__link--active"}},[_c('translate',{attrs:{"translate-params":{
                                        grade: level.grade.grade
                                    }}},[_vm._v(" Groep %{grade} ")])],1)],1)}),1)])],1)],1)],1),_c('AppLayoutMain',[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }