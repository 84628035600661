<template>
    <AppPage>
        <AppWrapper variant="normal">
            <div v-for="(levels, parent) in allEducationalLevels" :key="parent">
                <AppOverviewList variant="hover" class="u-margin-bottom">
                    <thead class="overview-list-head">
                        <tr>
                            <td colspan="2">
                                <h3 class="subtitle u-margin-bottom-small">
                                    {{ parent }}
                                </h3>
                            </td>
                        </tr>
                    </thead>
                    <tbody class="overview-list-body">
                        <tr
                            v-for="level in levels"
                            :key="level.id"
                            class="overview-list__row"
                        >
                            <td class="overview-list__column">
                                {{ level.subject.name }}
                            </td>
                            <td
                                class="overview-list__column overview-list__column--stars overview-list__column--small"
                            >
                                <AppInputEducationalLevel
                                    v-model="levelValues[level.id]"
                                    :grade="grade"
                                    :disabled="
                                        loading > 0 || !onLine || level.readonly
                                    "
                                    @change="changeLevel(level.id, $event)"
                                />
                            </td>
                        </tr>
                    </tbody>
                </AppOverviewList>
            </div>
        </AppWrapper>
    </AppPage>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppInputEducationalLevel from "@/components/AppInputEducationalLevel";
import AppOverviewList from "@/components/AppOverviewList.vue";
import AppPage from "@/components/AppPage.vue";
import AppWrapper from "@/components/AppWrapper.vue";
import ChangeLevel from "@/mixins/ChangeLevel";

import { consoleError } from "@/console";
import gql from "graphql-tag";

export default {
    name: "LevelDetail",
    components: {
        AppInputEducationalLevel,
        AppOverviewList,
        AppWrapper,
        AppPage
    },
    mixins: [ChangeLevel],
    props: {
        studentId: {
            type: String,
            required: true
        },
        year: {
            type: Number,
            required: true
        },
        grade: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            allEducationalLevels: {},
            levelValues: {}
        };
    },
    computed: mapGetters("online", ["onLine"]),
    apollo: {
        allEducationalLevels: {
            query: gql`
                query allEducationalLevelsLevelDetail(
                    $studentId: ID!
                    $year: Int!
                    $grade: Int!
                ) {
                    allEducationalLevels(
                        studentId: $studentId
                        year: $year
                        grade: $grade
                    ) {
                        edges {
                            node {
                                id
                                level
                                readonly
                                subject {
                                    id
                                    name
                                    parent {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    studentId: this.studentId,
                    year: this.year,
                    grade: this.grade
                };
            },
            update(data) {
                if (data.allEducationalLevels) {
                    const levels = data.allEducationalLevels.edges.map(
                        x => x.node
                    );

                    for (const level of levels) {
                        this.levelValues[level.id] = level.level;
                    }

                    return levels.reduce((acc, obj) => {
                        if (!acc[obj.subject.parent.name])
                            acc[obj.subject.parent.name] = [];
                        acc[obj.subject.parent.name].push(obj);
                        return acc;
                    }, {});
                } else {
                    return this.allEducationalLevels;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van onderwijsniveaus."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: mapActions("message", ["showMessage"])
};
</script>
