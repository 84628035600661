<template>
    <AppLayout>
        <AppWrapper v-if="formValues.documents.length > 0" variant="normal">
            <AppForm ref="form" lazy-validation @submit.prevent="formSubmit">
                <AppPage>
                    <AppPageHeader>
                        <div class="page-header-content">
                            <p v-if="student.modified" class="t-size-small">
                                <strong v-translate>Laatst bewerkt:</strong>
                                {{ student.modified | moment("LL") }}
                            </p>

                            <AppButton
                                class="page-header__button"
                                variant="secondary medium-with-icon"
                                :disabled="!onLine"
                                icon="check-small"
                                :loading="loading > 0"
                                :title="$gettext('Opslaan')"
                                type="submit"
                            >
                                <translate>Opslaan</translate>
                            </AppButton>
                        </div>
                    </AppPageHeader>

                    <AppButton
                        variant="primary medium-with-icon"
                        class="u-margin-bottom"
                        icon="plus"
                        @click.prevent="addDocument"
                    >
                        <translate>Document toevoegen</translate>
                    </AppButton>

                    <AppContent
                        v-for="document in formValues.documents"
                        :key="document.index"
                        variant="inline-form"
                    >
                        <AppFormGroup
                            v-if="!document.id"
                            variant="hide-messages inline"
                        >
                            <AppInputText
                                v-model="document.name"
                                :disabled="loading > 0"
                                :label="$gettext('Naam')"
                                :required="!!document.document"
                                :error-messages="
                                    formErrors.documents.forms[
                                        document.index
                                    ] &&
                                        formErrors.documents.forms[
                                            document.index
                                        ].name
                                "
                                type="text"
                            />
                            <AppInputFile
                                v-model="document.document"
                                :disabled="loading > 0"
                                :label="$gettext('Document')"
                                :required="!!document.name"
                                :error-messages="
                                    formErrors.documents.forms[
                                        document.index
                                    ] &&
                                        formErrors.documents.forms[
                                            document.index
                                        ].document
                                "
                            />
                        </AppFormGroup>
                        <div v-else>
                            <AppInputFile
                                v-model="document.document"
                                clearable
                                :disabled="loading > 0"
                                hide-input-on-value
                            />
                        </div>
                    </AppContent>
                </AppPage>
            </AppForm>
        </AppWrapper>
        <AppLayoutInner v-else-if="!loading" variant="background centered">
            <AppOverviewPlaceholder>
                <p v-translate>
                    Voeg (pdf, word, excel) documenten toe aan deze leerling
                    zodat deze centraal beschikbaar zijn.
                </p>

                <AppButton
                    slot="button"
                    variant="primary medium with-icon"
                    icon="plus"
                    @click.prevent="addDocument"
                >
                    <translate>Document toevoegen</translate>
                </AppButton>
            </AppOverviewPlaceholder>
        </AppLayoutInner>
    </AppLayout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppButton from "@/components/AppButton";
import AppContent from "@/components/AppContent";
import AppForm from "@/components/AppForm";
import AppFormGroup from "@/components/AppFormGroup.vue";
import AppInputFile from "@/components/AppInputFile";
import AppInputText from "@/components/AppInputText";
import AppLayout from "@/components/AppLayout.vue";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppPage from "@/components/AppPage.vue";
import AppPageHeader from "@/components/AppPageHeader.vue";
import AppWrapper from "@/components/AppWrapper.vue";
import FormSave from "@/mixins/FormSave";
import gql from "graphql-tag";

export default {
    name: "InformationDocuments",
    components: {
        AppPage,
        AppLayoutInner,
        AppFormGroup,
        AppPageHeader,
        AppWrapper,
        AppLayout,
        AppContent,
        AppOverviewPlaceholder,
        AppInputFile,
        AppInputText,
        AppButton,
        AppForm
    },
    mixins: [FormSave],
    props: {
        studentId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: 0,
            student: {
                documents: []
            },
            formValues: {
                documents: []
            },
            emptyDocument: {
                id: "",
                name: "",
                document: null,
                index: null,
                delete: false
            },
            emptyFormErrors: {
                documents: {
                    nonFormErrors: [],
                    forms: []
                }
            }
        };
    },
    computed: mapGetters("online", ["onLine"]),
    apollo: {
        student: {
            query: gql`
                query studentInformationDocuments($id: ID!) {
                    student(id: $id) {
                        id
                        modified
                        documents {
                            edges {
                                node {
                                    id
                                    name
                                    document {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    id: this.studentId
                };
            },
            update(data) {
                if (data.student) {
                    const student = { ...data.student };
                    student.documents = student.documents.edges.map((x, i) => {
                        return {
                            index: i,
                            id: atob(x.node.id).split(":")[1],
                            name: x.node.name,
                            document: {
                                ...x.node.document,
                                filename: x.node.name,
                                name: x.node.name
                            },
                            delete: false
                        };
                    });
                    this.setFormValues(student);
                    return student;
                } else {
                    return this.student;
                }
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        addDocument() {
            this.formValues.documents.unshift({
                ...this.emptyDocument,
                index: this.formValues.documents.length
            });
        },
        getMutation() {
            const documents = this.formValues.documents.sort(
                (a, b) => a.index - b.index
            );

            const input = {
                id: atob(this.studentId).split(":")[1],
                documentsTotalForms: this.formValues.documents.length,
                documentsInitialForms: this.student.documents.length,
                documents: documents.map(val => {
                    return {
                        id: val.id,
                        name: val.name,
                        document: { file: val.document },
                        delete: !!(val.document && val.document.clear)
                    };
                }),
                contactsTotalForms: 0,
                contactsInitialForms: 0
            };

            return {
                mutation: gql`
                    mutation studentInformationDocuments(
                        $input: StudentMutationInput!
                    ) {
                        student(input: $input) {
                            student {
                                id
                                modified
                                documents {
                                    edges {
                                        node {
                                            id
                                            name
                                            document {
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                            errors {
                                ... on ErrorType {
                                    field
                                    messages
                                }
                                ... on ErrorTypeFormset {
                                    field
                                    formIndex
                                    errors {
                                        field
                                        messages
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                }
            };
        },
        formSuccess() {
            this.showMessage({
                message: this.$gettext(
                    "Wijzigingen zijn succesvol opgeslagen."
                ),
                type: "success"
            });
        }
    }
};
</script>
