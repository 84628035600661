<template>
    <div class="report-card">
        <button class="report-card__link" v-on="$listeners">
            <div class="report-card-front">
                <header class="header report-card__header">
                    <h2 class="report-card__title">
                        <translate :translate-params="{ number }">
                            Rapport %{number}
                        </translate>
                    </h2>
                    <h1 class="report-card__subtitle">
                        <translate :translate-params="{ grade }">
                            Groep %{grade}
                        </translate>
                    </h1>
                </header>

                <figure class="report-card__icon-wrapper">
                    <AppIcon
                        variant="large blue-light"
                        class="report-card__icon"
                        name="report"
                    />
                </figure>

                <div class="report-card__date">
                    {{ finalized | moment("LL") }}
                </div>
            </div>
        </button>
    </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
export default {
    name: "AppReportCard",
    components: { AppIcon },
    props: {
        number: {
            type: Number,
            required: true
        },
        grade: {
            type: Number,
            required: true
        },
        finalized: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="sass">
.report-card
    position: relative

    min-height: 250px
    padding-bottom: ((260/210)*100%)

.report-card__link
    position: absolute
    top: 0
    left: 0

    display: block
    width: 100%
    height: 100%

    border-width: 0
    cursor: pointer
    background-color: transparent

    &::after
        position: absolute
        top: 2px
        left: 10px
        z-index: 0

        display: block
        width: calc(100% - 10px)
        height: 100%

        opacity: .47
        content: ""
        background-color: #fff
        border-radius: $global-border-radius-big
        box-shadow: 1px 1px 2px rgba(#000, .1)

    &:hover
        .report-card-front
            margin-top: -2px

    &:focus
        outline: none

.report-card-front
    position: relative
    z-index: 1

    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    height: 100%
    margin-top: -4px
    padding:
        top: 42px
        right: $spacing-regular
        bottom: $spacing-small
        left: $spacing-regular

    background-color: #fff
    border-radius: $global-border-radius-big
    box-shadow: 1px 1px 2px rgba(#000, .1)
    transition: margin-top $global-transition-animation 300ms


.report-card__title
    @include font-size(14px)

.report-card__subtitle
    @include font-size(20px)

.report-card__icon-wrapper
    margin-bottom: $spacing-regular

.report-card__date
    margin-top: auto

    @include font-size(12px)
</style>
