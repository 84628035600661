<template>
    <AppModal
        ref="modal"
        variant="sticky bordered"
        persistent
        size="large"
        :value="!!value"
        has-form
        form-class="form--large"
        @modalSubmit="formSubmit"
        @input="$emit('input', false)"
    >
        <template v-if="reportLoaded" slot="title">
            {{ report.student.fullName }}

            <span class="modal-header__subtitle">
                <translate :translate-params="{ number: report.number }">
                    Rapport %{number}
                </translate>
                |
                <translate
                    :translate-params="{
                        grade: report.grade.grade
                    }"
                >
                    Groep %{grade}
                </translate>
            </span>
        </template>

        <AppFormGroup
            v-for="reportSubject in formValues.reportSubjects"
            :key="reportSubject.id"
        >
            <AppHeader size="small">
                <AppTitle as="h3" class="subtitle">
                    {{ reportSubject.name }}
                </AppTitle>
            </AppHeader>

            <AppRow>
                <AppColumn>
                    <AppOverviewList
                        v-if="reportSubject.reportGrades.length"
                        class="overview-list--reports"
                    >
                        <tbody class="overview-list-body">
                            <tr
                                v-for="reportGrade in reportSubject.reportGrades"
                                :key="reportGrade.id"
                                :class="[
                                    'overview-list__row',
                                    {
                                        'overview-list__row--hover':
                                            reportGrade.isReportGrade
                                    }
                                ]"
                            >
                                <td class="overview-list__column">
                                    {{ reportGrade.name }}
                                </td>
                                <td
                                    class="overview-list__column overview-list__column--grade"
                                >
                                    <template v-if="reportGrade.isReportGrade">
                                        <AppInputEducationalLevelReport
                                            v-if="isDraft"
                                            :disabled="loading > 0 || !onLine"
                                            :grade="report.grade.grade"
                                            :value="reportGrade.grade"
                                            @change="
                                                changeReportGrade(
                                                    reportGrade.id,
                                                    $event
                                                )
                                            "
                                        />
                                        <AppEducationalLevelReport
                                            v-else
                                            :grade="report.grade.grade"
                                            :level="reportGrade.grade"
                                        />
                                    </template>
                                    <AppEducationalLevel
                                        v-else
                                        :grade="report.grade.grade"
                                        :level="reportGrade.grade"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </AppOverviewList>
                    <translate
                        v-else
                        class="t-color-blue-light"
                        tag="p"
                        :translate-params="{
                            subject: reportSubject.name
                        }"
                    >
                        Er is geen becijfering voor %{subject}
                    </translate>
                </AppColumn>
                <AppColumn>
                    <AppInputTextarea
                        v-if="isDraft"
                        v-model="reportSubject.comment"
                        :disabled="loading > 0"
                        :error-messages="
                            formErrors.reportSubjects.forms[
                                reportSubject.index
                            ] &&
                                formErrors.reportSubjects.forms[
                                    reportSubject.index
                                ].comment
                        "
                        :label="
                            $gettextInterpolate(
                                'Opmerking betreft %{subject}',
                                {
                                    subject: reportSubject.name
                                }
                            )
                        "
                        size="large"
                    />
                    <!-- eslint-disable-next-line -->
                    <p v-else-if="reportSubject.comment" class="t-pre-wrap">{{ reportSubject.comment }}</p>
                </AppColumn>
            </AppRow>
        </AppFormGroup>

        <AppHeader v-if="isDraft || formValues.comment" size="small">
            <AppTitle as="h3" class="subtitle">
                <translate>Algemene indruk</translate>
            </AppTitle>
        </AppHeader>

        <AppInputTextarea
            v-if="isDraft"
            v-model="formValues.comment"
            :disabled="loading > 0"
            :error-messages="formErrors.comment"
            :label="
                $gettextInterpolate('Algemene indruk van %{name}', {
                    name: report.student.firstName
                })
            "
            size="large"
        />
        <!-- eslint-disable-next-line -->
        <p v-else-if="formValues.comment" class="t-pre-wrap">{{ formValues.comment }}</p>

        <template #footer>
            <AppButtonGroup
                v-if="isDraft"
                :key="report.id"
                class="form-footer d-flex--direction-row-reverse u-margin-left-auto"
            >
                <AppButton
                    variant="secondary huge"
                    :disabled="!onLine"
                    :loading="loading > 0"
                    type="submit"
                    @click="goToNext = true"
                >
                    <translate>Opslaan & volgende leerling</translate>
                </AppButton>
                <AppButton
                    variant="link link-bold link-underline huge"
                    class="d-flex--align-right"
                    :disabled="!onLine"
                    :loading="loading > 0"
                    type="submit"
                >
                    <translate>Opslaan</translate>
                </AppButton>
            </AppButtonGroup>
            <AppButtonGroup
                v-else
                class="form-footer form-footer--centered d-flex--direction-row-reverse"
            >
                <AppButton
                    variant="secondary huge"
                    class="d-flex--align-right"
                    @click="$emit('input', undefined)"
                >
                    <translate>Oké</translate>
                </AppButton>

                <AppLink
                    v-if="report.pdf && report.pdf.url"
                    key="success"
                    as="a"
                    download
                    :href="report.pdf.url"
                    target="_blank"
                >
                    <AppIcon
                        class="link__icon u-margin-right-xsmall"
                        name="download"
                    />
                    <span class="t-decoration-underline">
                        <translate>Download rapport</translate>
                    </span>
                </AppLink>
            </AppButtonGroup>
        </template>
    </AppModal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import AppButton from "@/components/AppButton";
import AppButtonGroup from "@/components/AppButtonGroup.vue";
import AppColumn from "@/components/AppColumn.vue";
import AppEducationalLevel from "@/components/AppEducationalLevel";
import AppEducationalLevelReport from "@/components/AppEducationalLevelReport";
import AppFormGroup from "@/components/AppFormGroup.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppIcon from "@/components/AppIcon";
import AppInputEducationalLevelReport from "@/components/AppInputEducationalLevelReport";
import AppInputTextarea from "@/components/AppInputTextarea";
import AppLink from "@/components/AppLink.vue";
import AppModal from "@/components/AppModal";
import AppOverviewList from "@/components/AppOverviewList.vue";
import AppRow from "@/components/AppRow.vue";
import AppTitle from "@/components/AppTitle.vue";
import FormSave from "@/mixins/FormSave";
import { cloneDeep } from "lodash";
import gql from "graphql-tag";

export default {
    name: "AppReportDetailModal",
    components: {
        AppButtonGroup,
        AppIcon,
        AppEducationalLevelReport,
        AppInputEducationalLevelReport,
        AppEducationalLevel,
        AppInputTextarea,
        AppLink,
        AppOverviewList,
        AppColumn,
        AppRow,
        AppTitle,
        AppHeader,
        AppFormGroup,
        AppButton,
        AppModal
    },
    mixins: [FormSave],
    props: {
        value: {
            type: String,
            default: undefined
        }
    },
    data() {
        return {
            emptyFormErrors: {
                reportSubjects: {
                    nonFormErrors: [],
                    forms: []
                }
            },
            formValues: {
                comment: "",
                reportSubjects: []
            },
            goToNext: false,
            report: {
                reportSubjects: []
            },
            changingGrade: 0
        };
    },
    computed: {
        ...mapGetters("online", ["onLine"]),
        isDraft() {
            return this.report.id && this.report.status === "DRAFT";
        },
        numericId() {
            return atob(this.value).split(":")[1];
        },
        reportLoaded() {
            return this.report.id && this.value === this.report.id;
        }
    },
    apollo: {
        report: {
            query: gql`
                query reportAppReportDetailModal($id: ID!) {
                    report(id: $id) {
                        id
                        student {
                            id
                            fullName
                            firstName
                        }
                        grade {
                            id
                            grade
                        }
                        pdf {
                            url
                        }
                        status
                        number
                        comment
                        reportSubjects {
                            edges {
                                node {
                                    id
                                    name
                                    comment
                                    reportGrades {
                                        edges {
                                            node {
                                                id
                                                name
                                                grade
                                                isReportGrade
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    id: this.value
                };
            },
            update(data) {
                if (data.report) {
                    const report = { ...data.report };
                    const isDraft = report.status === "DRAFT";
                    report.reportSubjects = report.reportSubjects.edges
                        .map(({ node }, i) => {
                            const reportSubject = { ...node, index: i };
                            const currentReportSubject = this.formValues.reportSubjects.find(
                                x => x.index === i
                            );
                            if (this.changingGrade && currentReportSubject) {
                                reportSubject.comment =
                                    currentReportSubject.comment;
                            }
                            reportSubject.reportGrades = reportSubject.reportGrades.edges
                                .map(x => x.node)
                                .filter(node => isDraft || node.grade);
                            return reportSubject;
                        })
                        .filter(
                            node =>
                                isDraft ||
                                node.comment ||
                                node.reportGrades.length > 0
                        );
                    this.setFormValues(report);
                    return report;
                } else {
                    return this.report;
                }
            },
            skip() {
                return !this.value;
            }
        }
    },
    watch: {
        value(value) {
            if (value) {
                this.formErrors = cloneDeep(this.emptyFormErrors);
            }
        },
        report(report) {
            if (this.loading <= 0 && report && report.status === "DRAFT") {
                this.updateReport(report.id);
                this.$el.scrollTo && this.$el.scrollTo(0, 0);
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        getMutation() {
            const reportSubjects = this.formValues.reportSubjects.sort(
                (a, b) => a.index - b.index
            );

            const input = {
                id: this.numericId,
                comment: this.formValues.comment,
                reportSubjectsInitialForms: this.report.reportSubjects.length,
                reportSubjectsTotalForms: this.formValues.reportSubjects.length,
                reportSubjects: reportSubjects.map(val => {
                    return {
                        id: atob(val.id).split(":")[1],
                        comment: val.comment
                    };
                })
            };

            return {
                mutation: gql`
                    mutation reportAppReportDetailModal(
                        $input: ReportMutationInput!
                    ) {
                        report(input: $input) {
                            report {
                                id
                                comment
                                reportSubjects {
                                    edges {
                                        node {
                                            id
                                            comment
                                        }
                                    }
                                }
                                modified
                            }
                            errors {
                                ... on ErrorType {
                                    field
                                    messages
                                }
                                ... on ErrorTypeFormset {
                                    field
                                    formIndex
                                    errors {
                                        field
                                        messages
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: { input }
            };
        },
        formSuccess() {
            this.showMessage({
                message: this.$gettext(
                    "Wijzigingen zijn succesvol opgeslagen."
                ),
                type: "success"
            });
            if (this.goToNext) {
                this.goToNext = false;
                this.$emit("next");
            }
        },
        changeReportGrade(id, grade) {
            const numericId = atob(id).split(":")[1];

            this.loading++;
            this.changingGrade++;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation reportGrade(
                            $input: ReportGradeMutationInput!
                        ) {
                            reportGrade(input: $input) {
                                reportGrade {
                                    id
                                    grade
                                }
                                errors {
                                    field
                                    messages
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: numericId,
                            grade
                        }
                    }
                })
                .then(data => {
                    let errors = [];
                    for (const k in data.data) {
                        if (
                            Object.prototype.hasOwnProperty.call(data.data, k)
                        ) {
                            errors = errors.concat(data.data[k].errors || []);
                        }
                    }

                    if (errors.length === 0) {
                        this.showMessage({
                            message: this.$gettext(
                                "Cijfer is succesvol aangepast."
                            ),
                            type: "success"
                        });
                    } else {
                        this.formError();
                    }
                })
                .catch(err => {
                    this.formError(err);
                })
                .finally(() => {
                    this.loading--;
                    this.changingGrade--;
                });
        },
        updateReport(reportId) {
            this.loading++;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateReport($reportId: ID!) {
                            updateReport(reportId: $reportId) {
                                report {
                                    id
                                    reportSubjects {
                                        edges {
                                            node {
                                                id
                                                name
                                                comment
                                                reportGrades {
                                                    edges {
                                                        node {
                                                            id
                                                            name
                                                            grade
                                                            isReportGrade
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: { reportId }
                })
                .finally(() => {
                    this.loading--;
                });
        }
    }
};
</script>
