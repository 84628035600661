var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppLayout',[(_vm.formValues.documents.length > 0)?_c('AppWrapper',{attrs:{"variant":"normal"}},[_c('AppForm',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('AppPage',[_c('AppPageHeader',[_c('div',{staticClass:"page-header-content"},[(_vm.student.modified)?_c('p',{staticClass:"t-size-small"},[_c('strong',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Laatst bewerkt:")]),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.student.modified,"LL"))+" ")]):_vm._e(),_c('AppButton',{staticClass:"page-header__button",attrs:{"variant":"secondary medium-with-icon","disabled":!_vm.onLine,"icon":"check-small","loading":_vm.loading > 0,"title":_vm.$gettext('Opslaan'),"type":"submit"}},[_c('translate',[_vm._v("Opslaan")])],1)],1)]),_c('AppButton',{staticClass:"u-margin-bottom",attrs:{"variant":"primary medium-with-icon","icon":"plus"},on:{"click":function($event){$event.preventDefault();return _vm.addDocument.apply(null, arguments)}}},[_c('translate',[_vm._v("Document toevoegen")])],1),_vm._l((_vm.formValues.documents),function(document){return _c('AppContent',{key:document.index,attrs:{"variant":"inline-form"}},[(!document.id)?_c('AppFormGroup',{attrs:{"variant":"hide-messages inline"}},[_c('AppInputText',{attrs:{"disabled":_vm.loading > 0,"label":_vm.$gettext('Naam'),"required":!!document.document,"error-messages":_vm.formErrors.documents.forms[
                                    document.index
                                ] &&
                                    _vm.formErrors.documents.forms[
                                        document.index
                                    ].name,"type":"text"},model:{value:(document.name),callback:function ($$v) {_vm.$set(document, "name", $$v)},expression:"document.name"}}),_c('AppInputFile',{attrs:{"disabled":_vm.loading > 0,"label":_vm.$gettext('Document'),"required":!!document.name,"error-messages":_vm.formErrors.documents.forms[
                                    document.index
                                ] &&
                                    _vm.formErrors.documents.forms[
                                        document.index
                                    ].document},model:{value:(document.document),callback:function ($$v) {_vm.$set(document, "document", $$v)},expression:"document.document"}})],1):_c('div',[_c('AppInputFile',{attrs:{"clearable":"","disabled":_vm.loading > 0,"hide-input-on-value":""},model:{value:(document.document),callback:function ($$v) {_vm.$set(document, "document", $$v)},expression:"document.document"}})],1)],1)})],2)],1)],1):(!_vm.loading)?_c('AppLayoutInner',{attrs:{"variant":"background centered"}},[_c('AppOverviewPlaceholder',[_c('p',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" Voeg (pdf, word, excel) documenten toe aan deze leerling zodat deze centraal beschikbaar zijn. ")]),_c('AppButton',{attrs:{"slot":"button","variant":"primary medium with-icon","icon":"plus"},on:{"click":function($event){$event.preventDefault();return _vm.addDocument.apply(null, arguments)}},slot:"button"},[_c('translate',[_vm._v("Document toevoegen")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }