<template>
    <router-link
        :class="antlerClass()"
        :to="to"
        :active-class="activeClass"
        :exact-active-class="exactActiveClass"
    >
        <slot />
    </router-link>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppMenuLink",
    props: {
        to: {
            type: Object,
            required: true
        },
        activeClass: {
            type: String,
            default: ""
        },
        exactActiveClass: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("nav-menu__link", props);

        return {
            antlerClass
        };
    }
});
</script>
