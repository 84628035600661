<template>
    <TheLayout>
        <AppLayout variant="vertical">
            <AppHeader>
                <AppTitle v-translate>
                    Alle leerlingen
                </AppTitle>
            </AppHeader>

            <div v-if="allStudents.length > 0">
                <AppOverviewList class="u-margin-bottom">
                    <thead class="overview-list-head">
                        <tr class="overview-list__row">
                            <th
                                v-translate
                                class="overview-list__column"
                                colspan="2"
                            >
                                Naam
                            </th>
                            <th v-translate class="overview-list__column">
                                Groep
                            </th>
                        </tr>
                    </thead>
                    <tbody class="overview-list-body">
                        <router-link
                            v-for="student in allStudents"
                            :key="student.id"
                            class="overview-list__row overview-list__row--link"
                            tag="tr"
                            :to="{
                                name: 'student_insight',
                                params: { id: student.id }
                            }"
                        >
                            <td
                                class="overview-list__column overview-list__column--avatar"
                            >
                                <AppAvatar
                                    :image="
                                        student.profilePicture.url || undefined
                                    "
                                    size="small"
                                />
                            </td>
                            <td class="overview-list__column">
                                {{ student.fullName }}
                            </td>
                            <td class="overview-list__column">
                                <translate
                                    :translate-params="{
                                        name: student.currentGroup.name
                                    }"
                                >
                                    Groep %{name}
                                </translate>
                            </td>
                        </router-link>
                    </tbody>
                </AppOverviewList>

                <AppPagination
                    v-if="pagination.totalItems > rowsPerPage + orphans"
                    class="overview-list-pagination"
                    :pagination.sync="pagination"
                />
            </div>
            <AppLayoutInner
                v-else-if="!loading"
                variant="overflow-page background centered"
            >
                <AppOverviewPlaceholder>
                    <AppTitle
                        v-translate
                        as="h2"
                        class="overview-placeholder__title"
                    >
                        Geen leerlingen
                    </AppTitle>
                    <p v-translate>
                        U bent niet toegewezen aan een groep. Vraag een
                        beheerder om toegang te krijgen tot uw groep.
                    </p>
                </AppOverviewPlaceholder>
            </AppLayoutInner>
        </AppLayout>
    </TheLayout>
</template>

<script>
import AppAvatar from "@/components/AppAvatar";
import AppHeader from "@/components/AppHeader.vue";
import AppLayout from "@/components/AppLayout.vue";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppOverviewList from "@/components/AppOverviewList.vue";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppPagination from "@/components/AppPagination";
import AppTitle from "@/components/AppTitle.vue";
import TheLayout from "@/components/TheLayoutPage";

import { consoleError } from "@/console";
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
    name: "StudentOverview",
    components: {
        AppLayoutInner,
        AppOverviewList,
        AppTitle,
        AppHeader,
        AppLayout,
        AppOverviewPlaceholder,
        AppPagination,
        AppAvatar,
        TheLayout
    },
    props: {
        page: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            loading: 0,
            allStudents: [],
            rowsPerPage: 12,
            totalItems: 0,
            orphans: 3,
            sideButtons: 1
        };
    },
    computed: {
        pagination: {
            get() {
                return {
                    page: this.page,
                    rowsPerPage: this.rowsPerPage,
                    totalItems: this.totalItems,
                    sideButtons: this.sideButtons,
                    orphans: this.orphans
                };
            },
            set(newValue) {
                this.totalItems = newValue.totalItems;
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        page: newValue.page
                    }
                });
            }
        },
        after() {
            const currentOffset =
                (this.pagination.page - 1) * this.pagination.rowsPerPage - 1;
            return btoa(`arrayconnection:${currentOffset}`);
        },
        pageSize() {
            if (
                this.pagination.totalItems > 0 &&
                this.pagination.page * this.pagination.rowsPerPage +
                    this.pagination.orphans >=
                    this.pagination.totalItems
            ) {
                return this.pagination.rowsPerPage + this.pagination.orphans;
            } else {
                return this.pagination.rowsPerPage;
            }
        }
    },
    apollo: {
        allStudents: {
            query: gql`
                query allStudentsOverview($pageSize: Int!, $after: String!) {
                    allStudents(first: $pageSize, after: $after) {
                        totalCount
                        edges {
                            node {
                                id
                                fullName
                                profilePicture {
                                    url(
                                        options: {
                                            geometry: "100x100"
                                            asynchronous: true
                                            crop: "center"
                                        }
                                    )
                                }
                                currentGroup {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    pageSize: this.pageSize,
                    after: this.after
                };
            },
            update(data) {
                if (data.allStudents) {
                    this.pagination.totalItems = data.allStudents.totalCount;
                    return data.allStudents.edges.map(x => x.node);
                } else {
                    return this.allStudents;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van studenten."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: mapActions("message", ["showMessage"])
};
</script>
