<template>
    <select
        ref="input"
        :class="selectClasses"
        :disabled="isDisabled"
        :required="required"
        :value="value"
        @blur="onBlur"
        @change="onInput"
        @focus="onFocus"
    >
        <option value="">
            -
        </option>
        <option v-for="(v, key) in educationalLevels" :key="v" :value="key">
            {{ v }}
        </option>
    </select>
</template>

<script>
import AppInput from "@/components/AppInput";

export default {
    name: "AppInputEducationalLevelReport",
    extends: AppInput,
    model: {
        prop: "value",
        event: "change"
    },
    props: {
        value: {
            type: [String, Number],
            default: 1,
            validator: value => {
                value = parseInt(value);
                return 0 < value && value < 6;
            }
        }
    },
    computed: {
        educationalLevels() {
            return {
                1: "i",
                2: "ii",
                3: "iii",
                4: "iv",
                5: "v"
            };
        },
        selectClasses() {
            if (!this.value) return ["level", "level-select"];

            const classes = ["level-select"];
            if (this.educationalLevels[this.value]) {
                classes.push(
                    `level-select--report-${this.educationalLevels[this.value]}`
                );
            }
            return classes;
        }
    },
    methods: {
        focus() {
            this.onFocus();
        },
        blur() {
            this.$refs.input ? this.$refs.input.blur() : this.onBlur();
        },
        onBlur(event) {
            this.isFocused = false;
            this.$emit("blur", event);
        },
        onFocus(event) {
            if (!this.$refs.input) return;

            if (document.activeElement !== this.$refs.input) {
                return this.$refs.input.focus();
            }

            if (!this.isFocused) {
                this.isFocused = true;
                this.$emit("focus", event);
            }
        },
        onInput(event) {
            this.internalValue = event.target.value || null;
        }
    }
};
</script>

<style lang="sass">
.level-select
    display: inline-flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    padding: $spacing-xsmall

    @include font-size(14px, 1)
    font-weight: bold
    text-transform: uppercase
    text-align-last: center
    text-align: center

    appearance: none
    background-color: #fff
    border:
        width: 0
        radius: $global-border-radius-rounded
    box-shadow: 0 1px 2px rgba(#000, .3)

    &:focus
        outline: 0
        box-shadow: 0 0 0 2px currentColor

.level-select--report-v,
.level-select--report-iv,
.level-select--report-iii,
.level-select--report-ii,
.level-select--report-i
    font-family: $serif-font-family

.level-select--report-v
    color: $color-level-o

.level-select--report-iv
    color: $color-level-z

.level-select--report-iii
    color: $color-level-v

.level-select--report-ii
    color: $color-level-g

.level-select--report-i
    color: $color-level-zg

.level-select--fill
    border-radius: $global-border-radius-rounded

    color: #fff

    &.level-select--report-v
        background-color: $color-level-o

    &.level-select--report-iv
        background-color: $color-level-z

    &.level-select--report-iii
        background-color: $color-level-v

    &.level-select--report-ii
        background-color: $color-level-g

    &.level-select--report-i
        background-color: $color-level-zg

.level-select--outline
    border:
        radius: $global-border-radius-rounded
        width: 2px
        style: solid

    &.level-select--report-v
        border-color: $color-level-o

    &.level-select--report-iv
        border-color: $color-level-z

    &.level-select--report-iii
        border-color: $color-level-v

    &.level-select--report-ii
        border-color: $color-level-g

    &.level-select--report-i
        border-color: $color-level-zg
</style>
