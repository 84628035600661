<template>
    <AppPage>
        <AppWrapper variant="normal">
            <AppForm ref="form" lazy-validation @submit.prevent="formSubmit">
                <AppPageHeader>
                    <div class="page-header-content">
                        <p v-if="student.modified" class="t-size-small">
                            <strong v-translate>Laatst bewerkt:</strong>
                            {{ student.modified | moment("LL") }}
                        </p>

                        <AppButton
                            variant="secondary medium-with-icon"
                            class="page-header__button"
                            :disabled="!onLine"
                            icon="check-small"
                            :loading="loading > 0"
                            :title="$gettext('Opslaan')"
                            type="submit"
                        >
                            <translate>Opslaan</translate>
                        </AppButton>
                    </div>
                </AppPageHeader>

                <AppContent
                    variant="rounded background"
                    class="u-margin-bottom"
                >
                    <AppRow>
                        <AppColumn class="column-full--lte-tablet-portrait">
                            <AppHeader>
                                <AppTitle v-translate class="subtitle">
                                    Persoonsgegevens
                                </AppTitle>
                            </AppHeader>

                            <AppDetailList>
                                <dt v-translate class="detail-list__label">
                                    Roepnaam
                                </dt>
                                <dd class="detail-list__value">
                                    {{ student.callingName }}
                                </dd>
                                <dt v-translate class="detail-list__label">
                                    Voornaam
                                </dt>
                                <dd class="detail-list__value">
                                    {{ student.firstName }}
                                </dd>
                                <dt v-translate class="detail-list__label">
                                    Tussenvoegsel
                                </dt>
                                <dd class="detail-list__value">
                                    {{ student.infix || "-" }}
                                </dd>
                                <dt v-translate class="detail-list__label">
                                    Achternaam
                                </dt>
                                <dd class="detail-list__value">
                                    {{ student.lastName }}
                                </dd>
                                <dt v-translate class="detail-list__label">
                                    Geslacht
                                </dt>
                                <dd class="detail-list__value">
                                    {{ student.genderDisplay }}
                                </dd>
                                <dt v-translate class="detail-list__label">
                                    Geboortedatum
                                </dt>
                                <dd
                                    v-if="student.dateOfBirth"
                                    class="detail-list__value"
                                >
                                    {{ student.dateOfBirth | moment("L") }}
                                </dd>
                            </AppDetailList>
                        </AppColumn>
                    </AppRow>
                </AppContent>

                <AppSection class="u-margin-bottom">
                    <AppInputFileImage
                        v-model="formValues.profilePicture"
                        clearable
                        :disabled="loading > 0"
                        :error-messages="formErrors.profilePicture"
                        :hint="
                            $gettext(
                                'Upload een afbeelding van minimaal 200x200 pixels en maximaal 5MB groot.'
                            )
                        "
                        :label="$gettext('Profielfoto')"
                        persistent-hint
                    />
                </AppSection>

                <AppContent
                    class="u-margin-bottom"
                    size="medium"
                    variant="bordered-large"
                >
                    <AppHeader>
                        <AppTitle as="h3" size="xsmall">
                            {{ $gettext("Links- / rechtshandig") }}
                        </AppTitle>
                    </AppHeader>
                    <AppInputRadioGroup
                        v-model="formValues.handedness"
                        :disabled="loading > 0"
                        :error-messages="formErrors.handedness"
                        class="form-group--horizontal form-group--bordered"
                        :hide-details="!formErrors.handedness.length"
                    >
                        <AppInputRadio
                            :label="$gettext('Linkshandig')"
                            value="left"
                        />
                        <AppInputRadio
                            :label="$gettext('Rechtshandig')"
                            value="right"
                        />
                        <AppInputRadio
                            :label="$gettext('Nog onbepaald')"
                            value="tbd"
                        />
                    </AppInputRadioGroup>
                </AppContent>

                <AppHeader>
                    <AppTitle v-translate as="h2" class="subtitle">
                        Contactpersonen
                    </AppTitle>
                </AppHeader>

                <AppContentGroup class="u-margin-bottom">
                    <AppStudentContactPerson
                        v-for="contact in formValues.contacts"
                        :key="`${contact.index}-${contact.id}`"
                    >
                        <AppInputText
                            v-model="contact.name"
                            :disabled="loading > 0 || contact.delete"
                            :error-messages="
                                formErrors.contacts.forms[contact.index] &&
                                    formErrors.contacts.forms[contact.index]
                                        .name
                            "
                            :label="$gettext('Naam')"
                            type="text"
                        />
                        <AppInputText
                            v-model="contact.phoneNumber"
                            :disabled="loading > 0 || contact.delete"
                            :error-messages="
                                formErrors.contacts.forms[contact.index] &&
                                    formErrors.contacts.forms[contact.index]
                                        .phoneNumber
                            "
                            :label="$gettext('Telefoonnummer')"
                            type="text"
                        />
                        <AppInputText
                            v-model="contact.email"
                            autocomplete="email"
                            :disabled="loading > 0 || contact.delete"
                            :error-messages="
                                formErrors.contacts.forms[contact.index] &&
                                    formErrors.contacts.forms[contact.index]
                                        .email
                            "
                            :label="$gettext('E-mailadres')"
                            type="email"
                        />
                        <AppInputText
                            v-model="contact.relationToStudent"
                            :disabled="loading > 0 || contact.delete"
                            :error-messages="
                                formErrors.contacts.forms[contact.index] &&
                                    formErrors.contacts.forms[contact.index]
                                        .relationToStudent
                            "
                            :label="$gettext('Relatie tot leerling')"
                            type="text"
                        />
                        <template #action>
                            <AppInputCheckbox
                                v-model="contact.delete"
                                class="information__checkbox form-field--auto"
                                :disabled="loading > 0"
                                icon="trash"
                                icon-active-color="red"
                                hide-details
                            />
                        </template>
                    </AppStudentContactPerson>
                </AppContentGroup>

                <AppButton
                    variant="link"
                    class="u-margin-bottom"
                    :disabled="loading > 0"
                    @click="addContactRow"
                >
                    <AppIcon name="plus-circle" class="u-margin-right-tiny" />
                    <translate>Voeg een contact toe</translate>
                </AppButton>
            </AppForm>
        </AppWrapper>
    </AppPage>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppButton from "@/components/AppButton";
import AppColumn from "@/components/AppColumn";
import AppContent from "@/components/AppContent";
import AppContentGroup from "@/components/AppContentGroup";
import AppDetailList from "@/components/AppDetailList.vue";
import AppForm from "@/components/AppForm";
import AppHeader from "@/components/AppHeader";
import AppIcon from "@/components/AppIcon";
import AppInputCheckbox from "@/components/AppInputCheckbox";
import AppInputFileImage from "@/components/AppInputFileImage";
import AppInputRadio from "@/components/AppInputRadio";
import AppInputRadioGroup from "@/components/AppInputRadioGroup";
import AppInputText from "@/components/AppInputText";
import AppPage from "@/components/AppPage";
import AppPageHeader from "@/components/AppPageHeader.vue";
import AppRow from "@/components/AppRow";
import AppSection from "@/components/AppSection";
import AppStudentContactPerson from "@/components/AppStudentContactPerson.vue";
import AppTitle from "@/components/AppTitle.vue";
import AppWrapper from "@/components/AppWrapper.vue";
import FormSave from "@/mixins/FormSave";
import gql from "graphql-tag";

export default {
    name: "InformationBase",
    components: {
        AppStudentContactPerson,
        AppTitle,
        AppPageHeader,
        AppWrapper,
        AppDetailList,
        AppPage,
        AppSection,
        AppContentGroup,
        AppContent,
        AppHeader,
        AppColumn,
        AppRow,
        AppIcon,
        AppInputCheckbox,
        AppInputText,
        AppInputFileImage,
        AppButton,
        AppForm,
        AppInputRadioGroup,
        AppInputRadio
    },
    mixins: [FormSave],
    props: {
        studentId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            emptyContact: {
                index: null,
                id: "",
                name: "",
                phoneNumber: "",
                email: "",
                relationToStudent: "",
                delete: false
            },
            emptyFormErrors: {
                profilePicture: [],
                handedness: [],
                contacts: {
                    nonFormErrors: [],
                    forms: []
                }
            },
            formValues: {
                profilePicture: {},
                handedness: "tbd",
                contacts: []
            },
            loading: 0,
            student: {
                contacts: []
            }
        };
    },
    computed: mapGetters("online", ["onLine"]),
    apollo: {
        student: {
            query: gql`
                query studentInformationBase($id: ID!) {
                    student(id: $id) {
                        id
                        callingName
                        firstName
                        infix
                        lastName
                        dateOfBirth
                        genderDisplay
                        modified
                        handedness
                        profilePicture {
                            filename
                            url(
                                options: {
                                    geometry: "200x200"
                                    asynchronous: true
                                    crop: "center"
                                }
                            )
                        }
                        contacts {
                            edges {
                                node {
                                    id
                                    name
                                    phoneNumber
                                    email
                                    relationToStudent
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    id: this.studentId
                };
            },
            update(data) {
                if (data.student) {
                    const student = { ...data.student };
                    student.handedness = student.handedness.toLowerCase();
                    student.contacts = student.contacts.edges.map((x, i) => {
                        return {
                            index: i,
                            id: atob(x.node.id).split(":")[1],
                            name: x.node.name,
                            phoneNumber: x.node.phoneNumber,
                            email: x.node.email,
                            relationToStudent: x.node.relationToStudent,
                            delete: false
                        };
                    });
                    this.setFormValues(student);
                    return student;
                } else {
                    return this.student;
                }
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        addContactRow() {
            this.formValues.contacts.push({
                ...this.emptyContact,
                index: this.formValues.contacts.length
            });
        },
        getMutation() {
            const contacts = this.formValues.contacts.sort(
                (a, b) => a.index - b.index
            );

            const input = {
                id: atob(this.studentId).split(":")[1],
                profilePicture: {
                    file: this.formValues.profilePicture,
                    clear: this.formValues.profilePicture.clear
                },
                handedness: this.formValues.handedness,
                documentsTotalForms: 0,
                documentsInitialForms: 0,
                contactsTotalForms: this.formValues.contacts.length,
                contactsInitialForms: this.student.contacts.length,
                contacts: contacts.map(val => {
                    return {
                        id: val.id,
                        name: val.name,
                        phoneNumber: val.phoneNumber,
                        email: val.email,
                        relationToStudent: val.relationToStudent,
                        delete: val.delete
                    };
                })
            };

            return {
                mutation: gql`
                    mutation studentInformationBase(
                        $input: StudentMutationInput!
                    ) {
                        student(input: $input) {
                            student {
                                id
                                modified
                                handedness
                                profilePicture {
                                    filename
                                    url(
                                        options: {
                                            geometry: "200x200"
                                            asynchronous: true
                                            crop: "center"
                                        }
                                    )
                                }
                                contacts {
                                    edges {
                                        node {
                                            id
                                            name
                                            phoneNumber
                                            email
                                            relationToStudent
                                        }
                                    }
                                }
                            }
                            errors {
                                ... on ErrorType {
                                    field
                                    messages
                                }
                                ... on ErrorTypeFormset {
                                    field
                                    formIndex
                                    errors {
                                        field
                                        messages
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                },
                refetchQueries: [
                    {
                        query: gql`
                            query studentRefetchInformationBase($id: ID!) {
                                student(id: $id) {
                                    id
                                    profilePicture {
                                        url(
                                            options: {
                                                geometry: "100x100"
                                                asynchronous: true
                                                crop: "center"
                                            }
                                        )
                                    }
                                }
                            }
                        `,
                        variables: { id: this.studentId }
                    }
                ]
            };
        },
        formSuccess() {
            this.showMessage({
                message: this.$gettext(
                    "Wijzigingen zijn succesvol opgeslagen."
                ),
                type: "success"
            });
        }
    }
};
</script>

<style lang="sass">
.information__checkbox
    flex: 0 0 auto
    justify-content: center
    align-items: center
    padding: 0
    width: 48px
    height: 48px
</style>
