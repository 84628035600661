<template>
    <TheLayout class="layout--with-sidebar">
        <aside class="layout-side">
            <AppLayoutInner>
                <AppSection variant="side-menu">
                    <AppHeader class="section-header">
                        <AppTitle v-translate class="header__title">
                            Informatie
                        </AppTitle>
                    </AppHeader>

                    <AppMenuSide>
                        <ul class="nav-menu__list">
                            <AppMenuSideItem>
                                <AppMenuSideLink
                                    v-translate
                                    :to="{
                                        name: 'student_information_base',
                                        params: { id: studentId }
                                    }"
                                    exact-active-class="nav-side__link--active"
                                >
                                    Basis
                                </AppMenuSideLink>
                            </AppMenuSideItem>
                            <AppMenuSideItem>
                                <AppMenuSideLink
                                    v-translate
                                    :to="{
                                        name: 'student_information_documents',
                                        params: { id: studentId }
                                    }"
                                    exact-active-class="nav-side__link--active"
                                >
                                    Documenten
                                </AppMenuSideLink>
                            </AppMenuSideItem>
                            <AppMenuSideItem>
                                <AppMenuSideLink
                                    v-translate
                                    :to="{
                                        name: 'student_information_diagnosis',
                                        params: { id: studentId }
                                    }"
                                    exact-active-class="nav-side__link--active"
                                >
                                    Diagnose
                                </AppMenuSideLink>
                            </AppMenuSideItem>
                            <AppMenuSideItem>
                                <AppMenuSideLink
                                    v-translate
                                    :to="{
                                        name: 'student_information_talents',
                                        params: { id: studentId }
                                    }"
                                    exact-active-class="nav-side__link--active"
                                >
                                    Talenten
                                </AppMenuSideLink>
                            </AppMenuSideItem>
                        </ul>
                    </AppMenuSide>
                </AppSection>
            </AppLayoutInner>
        </aside>
        <AppLayoutMain>
            <router-view />
        </AppLayoutMain>
    </TheLayout>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppLayoutMain from "@/components/AppLayoutMain.vue";
import AppMenuSide from "@/components/AppMenuSide.vue";
import AppMenuSideItem from "@/components/AppMenuSideItem.vue";
import AppMenuSideLink from "@/components/AppMenuSideLink.vue";
import AppSection from "@/components/AppSection.vue";
import AppTitle from "@/components/AppTitle.vue";
import TheLayout from "@/components/TheLayoutDefault";

export default {
    name: "StudentInformation",
    components: {
        AppLayoutMain,
        AppMenuSideLink,
        AppMenuSideItem,
        AppMenuSide,
        AppTitle,
        AppHeader,
        AppSection,
        AppLayoutInner,
        TheLayout
    },
    props: {
        studentId: {
            type: String,
            required: true
        }
    }
};
</script>
