<template>
    <AppLayout variant="background">
        <template v-if="allInsights.length > 0">
            <AppLayoutInner>
                <AppHeader v-if="isAdmin" variant="overview">
                    <AppButton
                        variant="primary medium with-icon overview"
                        icon="plus"
                        @click="openModal({ insightType: 'diagnosis' })"
                    >
                        <translate>Diagnose toevoegen</translate>
                    </AppButton>
                </AppHeader>

                <AppWrapper
                    variant="normal pull-left"
                    :class="[{ 'wrapper--push-top': !isAdmin }]"
                >
                    <AppInsight
                        v-for="insight in allInsights"
                        :key="insight.id"
                        :insight="insight"
                    />
                </AppWrapper>
            </AppLayoutInner>
        </template>
        <template v-else-if="!loading">
            <AppLayoutInner variant="centered">
                <AppOverviewPlaceholder variant="small">
                    <AppTitle v-translate class="overview-placeholder__title">
                        Geen diagnoses!
                    </AppTitle>
                    <template v-if="isAdmin">
                        <p v-translate>
                            Vul de diagnoses in van deze leerling. Dit zijn
                            diagnoses die zijn vastgesteld door een derde.
                        </p>
                        <AppButton
                            slot="button"
                            variant="insight"
                            @click="openModal({ insightType: 'diagnosis' })"
                        >
                            <AppIcon name="plus" class="icon--tiny" />
                        </AppButton>
                    </template>
                    <p v-else v-translate>
                        Vraag de Interne Begeleider om een diagnose toe te
                        voegen als u een diagnose mist.
                    </p>
                </AppOverviewPlaceholder>
            </AppLayoutInner>
        </template>
    </AppLayout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import AppButton from "@/components/AppButton";
import AppHeader from "@/components/AppHeader.vue";
import AppIcon from "@/components/AppIcon";
import AppInsight from "@/components/AppInsight";
import AppLayout from "@/components/AppLayout.vue";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppTitle from "@/components/AppTitle.vue";
import AppWrapper from "@/components/AppWrapper.vue";

import { cloneDeep } from "lodash";
import { consoleError } from "@/console";
import gql from "graphql-tag";

export default {
    name: "InformationDiagnosis",
    components: {
        AppTitle,
        AppWrapper,
        AppHeader,
        AppLayoutInner,
        AppLayout,
        AppIcon,
        AppOverviewPlaceholder,
        AppInsight,
        AppButton
    },
    props: {
        studentId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allInsights: [],
            loading: 0
        };
    },
    computed: {
        ...mapGetters("auth", ["permissions"]),
        ...mapGetters("insightmodal", ["createdInsights"]),
        isAdmin() {
            return this.permissions === "admin";
        }
    },
    apollo: {
        allInsights: {
            query: gql`
                query allInsightsInformationDiagnosis(
                    $studentId: ID!
                    $types: [String]
                ) {
                    allInsights(studentId: $studentId, insightType: $types) {
                        edges {
                            node {
                                ... on Insight {
                                    id
                                    insightId
                                    type
                                    typeDisplay
                                    title
                                    insight
                                    date
                                    important
                                    attachment {
                                        filename
                                        url
                                    }
                                    encouraging
                                    dateSpecific
                                    dateWeekdays
                                    educationalLevelFrom
                                    educationalLevelTo
                                    subjects {
                                        edges {
                                            node {
                                                id
                                                name
                                            }
                                        }
                                    }
                                    created
                                    modified
                                    expired
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    studentId: this.studentId,
                    types: ["diagnosis"]
                };
            },
            update(data) {
                if (data.allInsights) {
                    return data.allInsights.edges.map(x => {
                        const node = cloneDeep(x.node);
                        node.type = node.type.toLowerCase();
                        node.subjects = node.subjects.edges.map(x => x.node);
                        return node;
                    });
                } else {
                    return this.allInsights;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van diagnoses."
                    ),
                    type: "error"
                });
            }
        }
    },
    watch: {
        createdInsights() {
            this.$apollo.queries.allInsights.refetch();
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        ...mapMutations("insightmodal", ["openModal"])
    }
};
</script>
