var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModal',{ref:"modal",attrs:{"variant":"sticky bordered","persistent":"","size":"large","value":!!_vm.value,"has-form":"","form-class":"form--large"},on:{"modalSubmit":_vm.formSubmit,"input":function($event){return _vm.$emit('input', false)}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.isDraft)?_c('AppButtonGroup',{key:_vm.report.id,staticClass:"form-footer d-flex--direction-row-reverse u-margin-left-auto"},[_c('AppButton',{attrs:{"variant":"secondary huge","disabled":!_vm.onLine,"loading":_vm.loading > 0,"type":"submit"},on:{"click":function($event){_vm.goToNext = true}}},[_c('translate',[_vm._v("Opslaan & volgende leerling")])],1),_c('AppButton',{staticClass:"d-flex--align-right",attrs:{"variant":"link link-bold link-underline huge","disabled":!_vm.onLine,"loading":_vm.loading > 0,"type":"submit"}},[_c('translate',[_vm._v("Opslaan")])],1)],1):_c('AppButtonGroup',{staticClass:"form-footer form-footer--centered d-flex--direction-row-reverse"},[_c('AppButton',{staticClass:"d-flex--align-right",attrs:{"variant":"secondary huge"},on:{"click":function($event){return _vm.$emit('input', undefined)}}},[_c('translate',[_vm._v("Oké")])],1),(_vm.report.pdf && _vm.report.pdf.url)?_c('AppLink',{key:"success",attrs:{"as":"a","download":"","href":_vm.report.pdf.url,"target":"_blank"}},[_c('AppIcon',{staticClass:"link__icon u-margin-right-xsmall",attrs:{"name":"download"}}),_c('span',{staticClass:"t-decoration-underline"},[_c('translate',[_vm._v("Download rapport")])],1)],1):_vm._e()],1)]},proxy:true}])},[(_vm.reportLoaded)?_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.report.student.fullName)+" "),_c('span',{staticClass:"modal-header__subtitle"},[_c('translate',{attrs:{"translate-params":{ number: _vm.report.number }}},[_vm._v(" Rapport %{number} ")]),_vm._v(" | "),_c('translate',{attrs:{"translate-params":{
                    grade: _vm.report.grade.grade
                }}},[_vm._v(" Groep %{grade} ")])],1)]):_vm._e(),_vm._l((_vm.formValues.reportSubjects),function(reportSubject){return _c('AppFormGroup',{key:reportSubject.id},[_c('AppHeader',{attrs:{"size":"small"}},[_c('AppTitle',{staticClass:"subtitle",attrs:{"as":"h3"}},[_vm._v(" "+_vm._s(reportSubject.name)+" ")])],1),_c('AppRow',[_c('AppColumn',[(reportSubject.reportGrades.length)?_c('AppOverviewList',{staticClass:"overview-list--reports"},[_c('tbody',{staticClass:"overview-list-body"},_vm._l((reportSubject.reportGrades),function(reportGrade){return _c('tr',{key:reportGrade.id,class:[
                                'overview-list__row',
                                {
                                    'overview-list__row--hover':
                                        reportGrade.isReportGrade
                                }
                            ]},[_c('td',{staticClass:"overview-list__column"},[_vm._v(" "+_vm._s(reportGrade.name)+" ")]),_c('td',{staticClass:"overview-list__column overview-list__column--grade"},[(reportGrade.isReportGrade)?[(_vm.isDraft)?_c('AppInputEducationalLevelReport',{attrs:{"disabled":_vm.loading > 0 || !_vm.onLine,"grade":_vm.report.grade.grade,"value":reportGrade.grade},on:{"change":function($event){return _vm.changeReportGrade(
                                                reportGrade.id,
                                                $event
                                            )}}}):_c('AppEducationalLevelReport',{attrs:{"grade":_vm.report.grade.grade,"level":reportGrade.grade}})]:_c('AppEducationalLevel',{attrs:{"grade":_vm.report.grade.grade,"level":reportGrade.grade}})],2)])}),0)]):_c('translate',{staticClass:"t-color-blue-light",attrs:{"tag":"p","translate-params":{
                        subject: reportSubject.name
                    }}},[_vm._v(" Er is geen becijfering voor %{subject} ")])],1),_c('AppColumn',[(_vm.isDraft)?_c('AppInputTextarea',{attrs:{"disabled":_vm.loading > 0,"error-messages":_vm.formErrors.reportSubjects.forms[
                            reportSubject.index
                        ] &&
                            _vm.formErrors.reportSubjects.forms[
                                reportSubject.index
                            ].comment,"label":_vm.$gettextInterpolate(
                            'Opmerking betreft %{subject}',
                            {
                                subject: reportSubject.name
                            }
                        ),"size":"large"},model:{value:(reportSubject.comment),callback:function ($$v) {_vm.$set(reportSubject, "comment", $$v)},expression:"reportSubject.comment"}}):(reportSubject.comment)?_c('p',{staticClass:"t-pre-wrap"},[_vm._v(_vm._s(reportSubject.comment))]):_vm._e()],1)],1)],1)}),(_vm.isDraft || _vm.formValues.comment)?_c('AppHeader',{attrs:{"size":"small"}},[_c('AppTitle',{staticClass:"subtitle",attrs:{"as":"h3"}},[_c('translate',[_vm._v("Algemene indruk")])],1)],1):_vm._e(),(_vm.isDraft)?_c('AppInputTextarea',{attrs:{"disabled":_vm.loading > 0,"error-messages":_vm.formErrors.comment,"label":_vm.$gettextInterpolate('Algemene indruk van %{name}', {
                name: _vm.report.student.firstName
            }),"size":"large"},model:{value:(_vm.formValues.comment),callback:function ($$v) {_vm.$set(_vm.formValues, "comment", $$v)},expression:"formValues.comment"}}):(_vm.formValues.comment)?_c('p',{staticClass:"t-pre-wrap"},[_vm._v(_vm._s(_vm.formValues.comment))]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }