<template>
    <div>
        <AppLink
            variant="underline"
            as="button"
            icon="download"
            @click.native="$emit('click', $event)"
        >
            <translate>
                Genereer PDF van inzichten
            </translate>
        </AppLink>

        <AppModal
            v-model="modalOpen"
            :closable="!isGenerating"
            persistent
            size="small"
            variant="centered"
        >
            <template #title>
                {{ exportTitle }}
            </template>

            <AppArticle class="u-margin-bottom">
                <p>
                    {{ exportText }}
                </p>
            </AppArticle>

            <AppLoader v-if="isGenerating" variant="large-orange centered" />
            <AppButton
                v-else-if="exportFile.url"
                v-translate
                :loading="loadingDownloadFiles.includes(exportFile.url)"
                @click="
                    downloadFile(
                        exportFile.url,
                        exportFile.filename,
                        handleDownloadSuccess
                    )
                "
            >
                Download PDF
            </AppButton>
        </AppModal>
    </div>
</template>

<script>
import AppArticle from "@/components/AppArticle";
import AppButton from "@/components/AppButton";
import AppLink from "@/components/AppLink";
import AppLoader from "@/components/AppLoader";
import AppModal from "@/components/AppModal";
import DownloadFile from "@/mixins/DownloadFile";
import gql from "graphql-tag";

function initialData() {
    return {
        exportFile: { url: "", filename: "" },
        exportState: "PENDING"
    };
}

export default {
    name: "AppInsightPdfButton",
    components: { AppButton, AppLoader, AppArticle, AppModal, AppLink },
    mixins: [DownloadFile],
    model: {
        prop: "exportId",
        event: "input"
    },
    props: {
        exportId: {
            type: String,
            default: ""
        }
    },
    data() {
        return initialData();
    },
    computed: {
        modalOpen: {
            get() {
                return !!this.exportId;
            },
            set(val) {
                // In case modal is closed setter wil be called with false
                if (!val) this.$emit("input", "");
            }
        },
        isGenerating() {
            return this.exportState === "PENDING" && this.modalOpen;
        },
        exportTitle() {
            if (this.exportState === "SUCCESS") {
                return this.$gettext("Je download is beschikbaar.");
            } else if (this.exportState === "PENDING") {
                return this.$gettext("Genereer PDF van inzichten");
            } else {
                return this.$gettext("Er ging iets fout.");
            }
        },
        exportText() {
            if (this.exportState === "SUCCESS") {
                return this.$gettext(
                    "Klik op onderstaande knop om de export te downloaden."
                );
            } else if (this.exportState === "PENDING") {
                return this.$gettext("Dit kan enkele seconden duren.");
            } else {
                return this.$gettext(
                    "Er is iets fout gegaan bij het aanmaken van de export. Probeer het alstublieft opnieuw."
                );
            }
        }
    },
    apollo: {
        insightExport: {
            query: gql`
                query($id: ID!) {
                    insightExport(id: $id) {
                        taskStatus
                        pdf {
                            url
                            filename
                        }
                    }
                }
            `,
            pollInterval: 1000,
            variables() {
                return {
                    id: this.exportId
                };
            },
            update(data) {
                if (data.insightExport) {
                    this.exportState = data.insightExport.taskStatus;

                    if (data.insightExport.taskStatus === "SUCCESS") {
                        this.exportFile = data.insightExport.pdf;
                    }
                }
            },
            skip() {
                return !this.isGenerating;
            }
        }
    },
    watch: {
        exportId() {
            Object.assign(this.$data, initialData());
        }
    },
    methods: {
        handleDownloadSuccess() {
            this.modalOpen = false;
        }
    }
};
</script>
