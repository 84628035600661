<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppFormFilter",
    props: {
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("form-filter", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.form-filter
    margin-bottom: $spacing-regular

.form-filter__list
    list-style: none

.form-filter__item:not(:last-child)
    margin-bottom: $spacing-small

.form-filter__label
    display: flex
    align-items: center

.form-filter__input
    margin-right: $spacing-small

    &.form-field--checkbox .label--active
        display: flex
        align-items: center

        padding-top: 0
</style>
