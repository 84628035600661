<template>
    <div class="report-card-grid">
        <slot />
    </div>
</template>

<script>
export default {
    name: "AppReportCardGrid"
};
</script>

<style lang="sass">
.report-card-grid
    display: grid
    gap: $spacing-regular
    grid-template-columns: repeat(2, 1fr)

    +mq-tablet-portrait
        grid-template-columns: repeat(4, 1fr)

    +mq-desktop
        grid-template-columns: repeat(6, 1fr)

    +mq-desktop-large
        grid-template-columns: repeat(8, 1fr)
</style>
