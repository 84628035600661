<template>
    <component :is="as" :class="antlerClass()">
        <slot />
    </component>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppWrapper",
    props: {
        as: {
            type: String,
            default: "div",
            validator: value => {
                return ["section", "div"].includes(value);
            }
        },
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("wrapper", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.wrapper
    position: relative

    width: 100%

.wrapper--normal
    max-width: 820px

.wrapper--medium
    max-width: 990px

.wrapper--large
    max-width: 1100px

.wrapper--pull-left
    +mq-tablet-portrait
        margin-left: -64px

.wrapper--push-top
    margin-top: 48px

.wrapper--push-right
    margin-left: 36px

.wrapper--overflow
    max-height: 100%
    max-width: 100%

    overflow-x: auto
</style>
