import { consoleError } from "@/console";
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
    data() {
        return {
            loading: 0
        };
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        changeLevel(id, level) {
            const numericId = atob(id).split(":")[1];

            this.loading++;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation educationalLevel(
                            $input: EducationalLevelMutationInput!
                        ) {
                            educationalLevel(input: $input) {
                                educationalLevel {
                                    id
                                    level
                                }
                                errors {
                                    field
                                    messages
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: numericId,
                            level: level
                        }
                    }
                })
                .then(data => {
                    let errors = [];
                    for (const k in data.data) {
                        if (
                            Object.prototype.hasOwnProperty.call(data.data, k)
                        ) {
                            errors = errors.concat(data.data[k].errors || []);
                        }
                    }

                    if (errors.length === 0) {
                        this.showMessage({
                            message: this.$gettext(
                                "Onderwijsniveau is succesvol aangepast."
                            ),
                            type: "success"
                        });
                    } else {
                        this.formError();
                    }
                })
                .catch(err => {
                    this.formError(err);
                })
                .finally(() => {
                    this.loading--;
                });
        },
        formError(err) {
            err && consoleError(err, this);
            this.$apollo.queries.allEducationalLevels.refresh();
            this.showMessage({
                message: this.$gettext(
                    "Er is een fout opgetreden bij het versturen, probeer het a.u.b. opnieuw."
                ),
                type: "error"
            });
        }
    }
};
