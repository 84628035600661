<template>
    <AppMenuLink
        :class="antlerClass()"
        :to="to"
        :active-class="activeClass"
        :exact-active-class="exactActiveClass"
    >
        <slot />
    </AppMenuLink>
</template>

<script>
import AppMenuLink from "@/components/AppMenuLink";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppMenuSideLink",
    components: { AppMenuLink },
    props: {
        to: {
            type: Object,
            required: true
        },
        activeClass: {
            type: String,
            default: ""
        },
        exactActiveClass: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("nav-side__link", props);

        return {
            antlerClass
        };
    }
});
</script>
