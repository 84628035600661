<template>
    <AppFormField
        :class="[
            'form-field--file-image',
            { 'form-field--error': hasMessages }
        ]"
    >
        <div class="media-upload">
            <div class="media-upload-preview">
                <AppAvatar
                    :image="
                        lazyValue && lazyValue.url ? lazyValue.url : undefined
                    "
                    size="huge"
                />
            </div>
            <div class="media-upload-form">
                <p
                    v-if="label"
                    :class="[
                        'media-upload-title',
                        { 'u-margin-bottom': lazyValue && lazyValue.url }
                    ]"
                >
                    {{ label }} <span v-if="required">*</span>
                </p>

                <p
                    v-if="lazyValue && lazyValue.name"
                    class="media-upload-description"
                >
                    {{ lazyValue.name }}
                </p>
                <p
                    v-else-if="!lazyValue || !lazyValue.url"
                    class="media-upload-description"
                >
                    <translate :translate-params="{ label }">
                        Geen gekozen %{label}
                    </translate>
                </p>

                <div class="media-upload-options">
                    <div class="media-upload-button has-button">
                        <input
                            ref="input"
                            :accept="accept"
                            :class="[
                                'input',
                                'input--file',
                                { 'form-field__input--active': isDirty }
                            ]"
                            :disabled="disabled"
                            :readonly="readonly"
                            :required="required"
                            type="file"
                            @blur="onBlur"
                            @change="onInput"
                            @focus="onFocus"
                        />
                        <AppButton v-translate variant="medium primary">
                            Bladeren
                        </AppButton>
                    </div>

                    <AppInputCheckbox
                        v-if="clearable && lazyValue && lazyValue.url"
                        v-model="lazyValue.clear"
                        class="media-upload-delete"
                        :label="$gettext('Afbeelding verwijderen')"
                        :disabled="isDisabled"
                    />
                </div>
            </div>
        </div>

        <div v-if="!hideDetails" class="form-field-messages">
            <AppMessageList
                v-if="hasMessages || hasHint"
                :messages="messages"
                :type="validationState"
            />
        </div>
    </AppFormField>
</template>

<script>
import AppAvatar from "@/components/AppAvatar";
import AppButton from "@/components/AppButton";
import AppInputCheckbox from "@/components/AppInputCheckbox";
import AppInputFile from "@/components/AppInputFile";
import AppMessageList from "@/components/AppMessageList";

export default {
    name: "AppInputFileImage",
    components: {
        AppAvatar,
        AppButton,
        AppInputCheckbox,
        AppMessageList
    },
    extends: AppInputFile,
    props: {
        accept: {
            type: String,
            default: "image/*"
        }
    }
};
</script>

<style lang="sass">
.media-upload
    display: flex
    align-items: center
    padding: $spacing-small

    background-color: #fff
    border:
        width: 2px
        color: $color-input-border
        style: solid
        radius: $input-border-radius

.media-upload-preview
    flex: 0 1 190px
    width: 190px
    padding-right: $spacing-regular
    margin-right: $spacing-regular

    border-right: 1px solid $color-blue-lighter

    +mq-lte-mobile
        flex-basis: 130px
        width: 130px

.media-upload-form
    flex: 1

    +mq-tablet-portrait
        padding: $spacing-small

.media-upload-title
    @include font-size(18px)
    font:
        family: $primary-font-family
        weight: $strong-weight

.media-upload-description
    margin-bottom: $spacing-xsmall

    @include font-size(14px)
    word-break: break-word

.media-upload-options
    display: flex
    align-items: flex-start

    +mq-lte-mobile
        flex-direction: column

        .media-upload-delete
            margin:
                top: $spacing-regular
                left: 0

.media-upload-delete
    margin-left: $spacing-small

.media-upload-button
    position: relative

/* States */
/*---------------------------------------------------------------------------*/

.form-field--error .media-upload
    border-color: $color-error
</style>
