<template>
    <AppPage>
        <AppWrapper variant="normal">
            <AppForm ref="form" lazy-validation @submit.prevent="formSubmit">
                <AppPageHeader>
                    <div class="page-header-content">
                        <p v-if="student.modified" class="t-size-small">
                            <strong v-translate>Laatst bewerkt:</strong>
                            {{ student.modified | moment("LL") }}
                        </p>

                        <AppButton
                            variant="secondary medium with-icon"
                            class="page-header__button"
                            :disabled="!onLine"
                            icon="check-small"
                            :loading="loading > 0"
                            :title="$gettext('Opslaan')"
                            type="submit"
                        >
                            <translate>Opslaan</translate>
                        </AppButton>
                    </div>
                </AppPageHeader>

                <AppInputTextarea
                    v-model="formValues.talents"
                    :disabled="loading > 0"
                    :error-messages="formErrors.talents"
                    :label="$gettext('De talenten van deze leerling')"
                />
            </AppForm>
        </AppWrapper>
    </AppPage>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppButton from "@/components/AppButton";
import AppForm from "@/components/AppForm";
import AppInputTextarea from "@/components/AppInputTextarea";
import AppPage from "@/components/AppPage.vue";
import AppPageHeader from "@/components/AppPageHeader.vue";
import AppWrapper from "@/components/AppWrapper.vue";

import FormSave from "@/mixins/FormSave";
import gql from "graphql-tag";

export default {
    name: "InformationTalents",
    components: {
        AppPageHeader,
        AppWrapper,
        AppPage,
        AppInputTextarea,
        AppButton,
        AppForm
    },
    mixins: [FormSave],
    props: {
        studentId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: 0,
            student: {},
            formValues: {
                talents: ""
            },
            emptyFormErrors: {
                talents: []
            }
        };
    },
    computed: mapGetters("online", ["onLine"]),
    apollo: {
        student: {
            query: gql`
                query($id: ID!) {
                    student(id: $id) {
                        id
                        talents
                        modified
                    }
                }
            `,
            variables() {
                return {
                    id: this.studentId
                };
            },
            update(data) {
                if (data.student) {
                    const student = { ...data.student };
                    this.setFormValues(student);
                    return student;
                } else {
                    return this.student;
                }
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        getMutation() {
            const input = {
                id: atob(this.studentId).split(":")[1],
                talents: this.formValues.talents,
                documentsTotalForms: 0,
                documentsInitialForms: 0,
                contactsTotalForms: 0,
                contactsInitialForms: 0
            };

            return {
                mutation: gql`
                    mutation student($input: StudentMutationInput!) {
                        student(input: $input) {
                            student {
                                id
                                talents
                                modified
                            }
                            errors {
                                ... on ErrorType {
                                    field
                                    messages
                                }
                                ... on ErrorTypeFormset {
                                    field
                                    formIndex
                                    errors {
                                        field
                                        messages
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                }
            };
        },
        formSuccess() {
            this.showMessage({
                message: this.$gettext(
                    "Wijzigingen zijn succesvol opgeslagen."
                ),
                type: "success"
            });
        }
    }
};
</script>
