import { mapActions } from "vuex";
import { saveAs } from "file-saver";

export default {
    data() {
        return {
            loadingDownloadFiles: []
        };
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        downloadFile(url, fileName, successCallback) {
            successCallback = successCallback || function() {};
            this.loadingDownloadFiles.push(url);

            let headers = {};
            fetch(url, { headers })
                .then(res => {
                    if (res.ok) {
                        return res.blob();
                    } else {
                        throw new Error(`HTTP status ${res.status}`);
                    }
                })
                .then(blob => saveAs(blob, fileName))
                .then(successCallback)
                .catch(() => {
                    this.showMessage({
                        message: this.$gettextInterpolate(
                            "Het downloaden van bestand %{fileName} is mislukt, probeer het a.u.b. opnieuw.",
                            { fileName }
                        ),
                        type: "error"
                    });
                })
                .finally(() => {
                    this.loadingDownloadFiles = this.loadingDownloadFiles.filter(
                        x => x !== url
                    );
                });
        }
    }
};
