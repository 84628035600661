<template>
    <AppMenu :class="antlerClass()">
        <slot />
    </AppMenu>
</template>

<script>
import AppMenu from "@/components/AppMenu";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppMenuSide",
    components: { AppMenu },
    props: {
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("nav-side", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.nav-side
    margin-top: $spacing-small

    @include font-size(18px)
    font-family: $primary-font-family

.nav-side__item
    margin-bottom: $spacing-regular

    +mq-lte-tablet-portrait
        margin-bottom: $spacing-small

        @include font-size(14px)

.nav-side__link
    display: inline-block

    border-bottom:
        width: 1px
        style: dashed
        color: transparent

    font-weight: $strong-weight

.nav-side__link--active
    color: $color-secondary
    border-bottom:
        width: 1px
        style: dashed
        color: $color-secondary

.nav-side--small
    @include font-size(16px)

    .nav-side__item
        margin-bottom: $spacing-small
</style>
