<template>
    <TheLayout class="layout--vertical">
        <AppLayoutInner variant="background">
            <AppHeader>
                <AppTitle v-translate>
                    Rapporten
                </AppTitle>
            </AppHeader>

            <AppReportCardGrid v-if="allReports.length > 0">
                <AppReportCard
                    v-for="report in allReports"
                    :key="report.id"
                    :number="report.number"
                    :grade="report.grade.grade"
                    :finalized="report.finalized"
                    @click="reportId = report.id"
                />
            </AppReportCardGrid>
            <AppLayoutInner
                v-else-if="!loading"
                variant="overflow-page background centered"
            >
                <AppOverviewPlaceholder>
                    <AppTitle v-translate class="overview-placeholder__title">
                        Geen definitieve rapporten
                    </AppTitle>
                    <p v-translate>
                        Er zijn geen definitieve rapporten voor deze leerling. U
                        kunt rapporten genereren voor de leerling bij de groep.
                    </p>
                </AppOverviewPlaceholder>
            </AppLayoutInner>
        </AppLayoutInner>
        <AppReportDetailModal v-model="reportId" />
    </TheLayout>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppReportCard from "@/components/AppReportCard";
import AppReportCardGrid from "@/components/AppReportCardGrid.vue";
import AppReportDetailModal from "@/components/AppReportDetailModal";
import AppTitle from "@/components/AppTitle.vue";
import TheLayout from "@/components/TheLayoutDefault";
import { consoleError } from "@/console";
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
    name: "StudentReport",
    components: {
        AppReportCardGrid,
        AppTitle,
        AppHeader,
        AppLayoutInner,
        AppReportDetailModal,
        AppOverviewPlaceholder,
        AppReportCard,
        TheLayout
    },
    props: {
        studentId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allReports: [],
            loading: 0,
            reportId: undefined
        };
    },
    computed: {},
    apollo: {
        allReports: {
            query: gql`
                query allReportsStudentReport($studentId: ID!) {
                    allReports(studentId: $studentId, isFinal: true) {
                        edges {
                            node {
                                id
                                number
                                grade {
                                    id
                                    grade
                                }
                                finalized
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    studentId: this.studentId
                };
            },
            update(data) {
                if (data.allReports) {
                    return data.allReports.edges.map(x => x.node);
                } else {
                    return this.allReports;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van rapporten."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: mapActions("message", ["showMessage"])
};
</script>
