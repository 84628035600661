<template>
    <select
        ref="input"
        :class="selectClasses"
        :disabled="isDisabled"
        :required="required"
        :value="value"
        @blur="onBlur"
        @change="onInput"
        @focus="onFocus"
    >
        <option v-for="(v, key) in educationalLevels" :key="v" :value="key">
            {{ v }}
        </option>
    </select>
</template>

<script>
import AppInput from "@/components/AppInput";

export default {
    name: "AppInputEducationalLevel",
    extends: AppInput,
    model: {
        prop: "value",
        event: "change"
    },
    props: {
        grade: {
            type: Number,
            required: true
        },
        value: {
            type: [String, Number],
            default: 3,
            validator: value => {
                value = parseInt(value);
                return 0 < value && value < 7;
            }
        }
    },
    computed: {
        educationalLevels() {
            return {
                1: this.grade <= 2 ? "i.o." : "o",
                2: "z",
                3: "v",
                4: "rv",
                5: "g",
                6: "zg"
            };
        },
        selectClasses() {
            const classes = ["level-select"];
            if (this.educationalLevels[this.value]) {
                classes.push(
                    `level-select--${this.educationalLevels[
                        this.value
                    ].replaceAll(".", "")}`
                );
            }
            return classes;
        }
    },
    methods: {
        focus() {
            this.onFocus();
        },
        blur() {
            this.$refs.input ? this.$refs.input.blur() : this.onBlur();
        },
        onBlur(event) {
            this.isFocused = false;
            this.$emit("blur", event);
        },
        onFocus(event) {
            if (!this.$refs.input) return;

            if (document.activeElement !== this.$refs.input) {
                return this.$refs.input.focus();
            }

            if (!this.isFocused) {
                this.isFocused = true;
                this.$emit("focus", event);
            }
        },
        onInput(event) {
            this.internalValue = event.target.value;
        }
    }
};
</script>

<style lang="sass">
.level-select
    display: inline-flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    padding: $spacing-xsmall

    @include font-size(14px, 1)
    font-weight: bold
    text-transform: uppercase
    text-align-last: center
    text-align: center

    appearance: none
    background-color: #fff
    border:
        width: 0
        radius: $global-border-radius-rounded
    box-shadow: 0 1px 2px rgba(#000, .3)

    &:focus
        outline: 0
        box-shadow: 0 0 0 2px currentColor

.level-select--o,
.level-select--io
    color: $color-level-o

.level-select--z
    color: $color-level-z

.level-select--v
    color: $color-level-v

.level-select--rv
    color: $color-level-rv

.level-select--g
    color: $color-level-g

.level-select--zg
    color: $color-level-zg

.level-select--fill
    border-radius: $global-border-radius-rounded

    color: #fff

    &.level-select--o
        background-color: $color-level-o

    &.level-select--z
        background-color: $color-level-z

    &.level-select--v
        background-color: $color-level-v

    &.level-select--rv
        background-color: $color-level-rv

    &.level-select--g
        background-color: $color-level-g

    &.level-select--zg
        background-color: $color-level-zg

.level-select--outline
    border:
        radius: $global-border-radius-rounded
        width: 2px
        style: solid

    &.level-select--o
        border-color: $color-level-o

    &.level-select--z
        border-color: $color-level-z

    &.level-select--v
        border-color: $color-level-v

    &.level-select--rv
        border-color: $color-level-rv

    &.level-select--g
        border-color: $color-level-g

    &.level-select--zg
        border-color: $color-level-zg
</style>
