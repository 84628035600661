<template>
    <li :class="antlerClass()">
        <slot />
    </li>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppMenuItem",
    setup() {
        const { antlerClass } = antlerComponent("nav-menu__item");

        return {
            antlerClass
        };
    }
};
</script>
