<template>
    <nav :class="antlerClass()">
        <ul class="nav-menu__list">
            <slot />
        </ul>
    </nav>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppMenu",
    setup() {
        const { antlerClass } = antlerComponent("nav-menu");

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.nav-menu
    position: relative

.nav-menu__list
    list-style: none

.nav-menu__link
    display: block
</style>
