<template>
    <TheLayout class="layout--with-sidebar">
        <aside class="layout-side">
            <AppLayoutInner>
                <AppSection variant="side-menu">
                    <AppHeader class="section-header">
                        <AppTitle v-translate class="section-header__title">
                            Onderwijsniveau
                        </AppTitle>
                    </AppHeader>

                    <AppMenuSide>
                        <ul class="nav-menu__list">
                            <AppMenuSideItem
                                v-for="level in allEducationalLevels"
                                :key="level.id"
                            >
                                <AppMenuSideLink
                                    :to="{
                                        name: 'student_level_detail',
                                        params: {
                                            year: level.year,
                                            grade: level.grade.grade
                                        }
                                    }"
                                    exact-active-class="nav-side__link--active"
                                >
                                    <translate
                                        :translate-params="{
                                            grade: level.grade.grade
                                        }"
                                    >
                                        Groep %{grade}
                                    </translate>
                                </AppMenuSideLink>
                            </AppMenuSideItem>
                        </ul>
                    </AppMenuSide>
                </AppSection>
            </AppLayoutInner>
        </aside>
        <AppLayoutMain>
            <router-view />
        </AppLayoutMain>
    </TheLayout>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppLayoutMain from "@/components/AppLayoutMain.vue";
import AppMenuSide from "@/components/AppMenuSide.vue";
import AppMenuSideItem from "@/components/AppMenuSideItem.vue";
import AppMenuSideLink from "@/components/AppMenuSideLink.vue";
import AppSection from "@/components/AppSection.vue";
import AppTitle from "@/components/AppTitle.vue";
import TheLayout from "@/components/TheLayoutDefault";

import { consoleError } from "@/console";
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
    name: "StudentLevel",
    components: {
        AppLayoutMain,
        AppMenuSideLink,
        AppMenuSideItem,
        AppMenuSide,
        AppTitle,
        AppHeader,
        AppLayoutInner,
        AppSection,
        TheLayout
    },
    props: {
        studentId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allEducationalLevels: {},
            loading: 0
        };
    },
    apollo: {
        allEducationalLevels: {
            query: gql`
                query allEducationalLevelsStudentLevel($studentId: ID!) {
                    allEducationalLevels(studentId: $studentId) {
                        edges {
                            node {
                                id
                                year
                                grade {
                                    id
                                    grade
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    studentId: this.studentId
                };
            },
            update(data) {
                if (data.allEducationalLevels) {
                    return data.allEducationalLevels.edges
                        .map(x => x.node)
                        .filter(
                            (level, index, levels) =>
                                index ===
                                levels.findIndex(
                                    x =>
                                        x.year === level.year &&
                                        x.grade.grade === level.grade.grade
                                )
                        );
                } else {
                    return this.allEducationalLevels;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van onderwijsniveaus."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: mapActions("message", ["showMessage"])
};
</script>
