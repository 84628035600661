<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppDetailList",
    props: {
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("detail-list", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.detail-list
    position: relative
    display: flex
    flex-wrap: wrap
    align-items: flex-start

.detail-list__label
    flex: 0 0 160px
    overflow: hidden

.detail-list__value
    margin-left: 0
    flex: 0 0 calc(100% - 200px)

.detail-list__value
    position: relative

    font-weight: $strong-weight

    &::before
        display: inline-block
        margin-right: 4px

        content: ":"
</style>
