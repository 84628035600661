var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppFormField',{class:[
        'form-field--file-image',
        { 'form-field--error': _vm.hasMessages }
    ]},[_c('div',{staticClass:"media-upload"},[_c('div',{staticClass:"media-upload-preview"},[_c('AppAvatar',{attrs:{"image":_vm.lazyValue && _vm.lazyValue.url ? _vm.lazyValue.url : undefined,"size":"huge"}})],1),_c('div',{staticClass:"media-upload-form"},[(_vm.label)?_c('p',{class:[
                    'media-upload-title',
                    { 'u-margin-bottom': _vm.lazyValue && _vm.lazyValue.url }
                ]},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e(),(_vm.lazyValue && _vm.lazyValue.name)?_c('p',{staticClass:"media-upload-description"},[_vm._v(" "+_vm._s(_vm.lazyValue.name)+" ")]):(!_vm.lazyValue || !_vm.lazyValue.url)?_c('p',{staticClass:"media-upload-description"},[_c('translate',{attrs:{"translate-params":{ label: _vm.label }}},[_vm._v(" Geen gekozen %{label} ")])],1):_vm._e(),_c('div',{staticClass:"media-upload-options"},[_c('div',{staticClass:"media-upload-button has-button"},[_c('input',{ref:"input",class:[
                            'input',
                            'input--file',
                            { 'form-field__input--active': _vm.isDirty }
                        ],attrs:{"accept":_vm.accept,"disabled":_vm.disabled,"readonly":_vm.readonly,"required":_vm.required,"type":"file"},on:{"blur":_vm.onBlur,"change":_vm.onInput,"focus":_vm.onFocus}}),_c('AppButton',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"variant":"medium primary"}},[_vm._v(" Bladeren ")])],1),(_vm.clearable && _vm.lazyValue && _vm.lazyValue.url)?_c('AppInputCheckbox',{staticClass:"media-upload-delete",attrs:{"label":_vm.$gettext('Afbeelding verwijderen'),"disabled":_vm.isDisabled},model:{value:(_vm.lazyValue.clear),callback:function ($$v) {_vm.$set(_vm.lazyValue, "clear", $$v)},expression:"lazyValue.clear"}}):_vm._e()],1)])]),(!_vm.hideDetails)?_c('div',{staticClass:"form-field-messages"},[(_vm.hasMessages || _vm.hasHint)?_c('AppMessageList',{attrs:{"messages":_vm.messages,"type":_vm.validationState}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }