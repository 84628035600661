<template>
    <AppLayout as="main" :class="antlerClass()" :variant="layoutVariant">
        <slot />
    </AppLayout>
</template>

<script>
import AppLayout from "@/components/AppLayout";
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppLayoutMain",
    components: { AppLayout },
    props: {
        layoutVariant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("layout-main", props);

        return {
            antlerClass
        };
    }
};
</script>
