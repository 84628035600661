<template>
    <div :class="antlerClass()">
        <div :class="antlerClass('fields')">
            <slot />
        </div>
        <div :class="antlerClass('action')">
            <slot name="action" />
        </div>
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppStudentContactPerson",
    setup() {
        const { antlerClass } = antlerComponent("student-contact-person");

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.student-contact-person
    display: flex
    gap: 24px
    padding: 24px 12px 24px 24px
    margin-bottom: 20px

    border-radius: 4px
    background-color: $color-blue-lightest
    border: 1px solid $color-blue-lighter

    &:last-child
        margin-bottom: 0

    .form-field-messages
        min-height: 0

        &:empty
            margin:
                top: 0
                bottom: 0

.student-contact-person__fields
    display: grid
    gap: 20px
    flex-grow: 1

    +mq-tablet
        grid-template-columns: repeat(2, 1fr)

.student-contact-person__action
    display: flex
    align-items: center
    padding-left: 12px

    border-left: 1px solid $color-blue-lighter
</style>
