<template>
    <nav class="pagination">
        <ul class="pagination-list">
            <li class="pagination__item">
                <AppButton
                    v-translate
                    variant="link"
                    class="pagination__button"
                    type="button"
                    :disabled="isInFirstPage"
                    @click="updatePagination({ page: pagination.page - 1 })"
                >
                    Vorige
                </AppButton>
            </li>

            <li
                v-for="(page, index) in pages"
                :key="index"
                class="pagination__item"
            >
                <AppButton
                    type="button"
                    :disabled="page.isDisabled"
                    variant="link"
                    :class="[
                        'pagination__button',
                        {
                            'pagination__button--active': isPageActive(
                                page.name
                            )
                        }
                    ]"
                    @click="updatePagination({ page: page.name })"
                >
                    {{ page.name }}
                </AppButton>
            </li>

            <li class="pagination__item">
                <AppButton
                    v-translate
                    variant="link"
                    class="pagination__button"
                    type="button"
                    :disabled="isInLastPage"
                    @click="updatePagination({ page: pagination.page + 1 })"
                >
                    Volgende
                </AppButton>
            </li>
        </ul>
    </nav>
</template>

<script>
import AppButton from "@/components/AppButton";

export default {
    name: "AppPagination",
    components: { AppButton },
    props: {
        pagination: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            defaultPagination: {
                sideButtons: 1,
                page: 1,
                rowsPerPage: 12,
                totalItems: 0,
                orphans: 3
            }
        };
    },
    computed: {
        numPages() {
            const orphans = this.pagination.orphans,
                rowsPerPage = this.pagination.rowsPerPage,
                totalItems = this.pagination.totalItems;
            let numPages = Math.floor(totalItems / rowsPerPage);
            if (totalItems - numPages * rowsPerPage > orphans) {
                numPages += 1;
            }
            return Math.max(numPages, 1);
        },
        hasPagination() {
            return Object.keys(this.pagination).length > 0;
        },
        pages() {
            const page = this.pagination.page,
                range = [],
                sideButtons = this.pagination.sideButtons;
            const maxPages = 1 + 2 * sideButtons;

            let startPage;
            let endPage;
            if (this.numPages <= maxPages + 4) {
                startPage = 1;
                endPage = Math.min(this.numPages, maxPages + 4);
            } else if (page <= 3 + sideButtons) {
                startPage = 1;
                endPage = Math.min(this.numPages, maxPages + 2);
            } else if (page > this.numPages - (3 + sideButtons)) {
                startPage = this.numPages - (2 * sideButtons + 2);
                endPage = this.numPages;
            } else {
                startPage = page - sideButtons;
                endPage = page + sideButtons;
            }

            for (let i = startPage; i <= endPage; i += 1) {
                range.push(this.addPage(i));
            }
            if (startPage >= 2) {
                range.unshift({
                    name: "...",
                    isDisabled: true
                });
                range.unshift(this.addPage(1));
            }
            if (endPage < this.numPages - 1) {
                range.push({
                    name: "...",
                    isDisabled: true
                });
                range.push(this.addPage(this.numPages));
            }
            return range;
        },
        isInFirstPage() {
            return this.pagination.page === 1;
        },
        isInLastPage() {
            return this.pagination.page === this.numPages;
        }
    },
    created() {
        this.initPagination();
    },
    methods: {
        addPage(name) {
            return {
                name: name,
                isDisabled: this.isPageActive(name)
            };
        },
        isPageActive(page) {
            return this.pagination.page === page;
        },
        initPagination() {
            this.updatePagination(
                Object.assign({}, this.defaultPagination, this.pagination)
            );
        },
        updatePagination(val) {
            const pagination = this.hasPagination
                ? this.pagination
                : this.defaultPagination;
            const updatedPagination = Object.assign({}, pagination, val);
            this.$emit("update:pagination", updatedPagination);

            if (!this.hasPagination) {
                this.defaultPagination = updatedPagination;
            }
        }
    }
};
</script>

<style lang="sass">
.pagination
    display: flex
    justify-content: center

.pagination-list
    display: flex
    align-items: center

    list-style: none

    +mq-lte-mobile
        flex-wrap: wrap
        justify-content: center

.pagination__item
    +mq-lte-mobile
        &:first-child,
        &:last-child
            flex-basis: 100%

            text-align: center

    +mq-tablet
        padding: 5px

.pagination__button
    justify-content: center
    min-width: 28px
    height: 28px
    padding:
        top: 4px
        right: 8px
        bottom: 4px
        left: 8px

    @include font-size(16px, 1)
    font:
        family: $button-font-family
        weight: $button-font-weight

    border-radius: $global-border-radius-rounded

    &:hover
        background-color: $color-blue-lightest

    &:disabled:hover
        background-color: transparent

.pagination__button--active,
.pagination__button--active:hover,
.pagination__button--active:disabled,
.pagination__button--active:disabled:hover
    color: #fff

    background-color: $color-primary
</style>
