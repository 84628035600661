<template>
    <div :class="classes">
        {{ educationalLevelLetter }}
    </div>
</template>

<script>
export default {
    name: "AppEducationalLevel",
    props: {
        grade: {
            type: Number,
            required: true
        },
        level: {
            type: [String, Number],
            default: 3,
            validator: value => {
                value = parseInt(value);
                return 0 < value && value < 7;
            }
        },
        variant: {
            type: String,
            default: undefined,
            validator: value => {
                return ["fill", "outline"].includes(value);
            }
        }
    },
    computed: {
        classes() {
            const classes = ["level"];
            if (this.variant) classes.push(`level--${this.variant}`);
            if (this.educationalLevelLetter) {
                return [
                    ...classes,
                    `level--${this.educationalLevelLetter.replaceAll(".", "")}`
                ];
            }
            return classes;
        },
        educationalLevelLetter() {
            const educationalLevels = {
                1: this.grade <= 2 ? "i.o." : "o",
                2: "z",
                3: "v",
                4: "rv",
                5: "g",
                6: "zg"
            };
            return educationalLevels[this.level];
        }
    }
};
</script>

<style lang="sass">
.level
    display: inline-flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    padding: $spacing-xsmall

    @include font-size(14px, 1)
    font-weight: bold
    text-transform: uppercase
    text-align-last: center
    text-align: center

.level--o,
.level--io
    color: $color-level-o

.level--z
    color: $color-level-z

.level--v
    color: $color-level-v

.level--rv
    color: $color-level-rv

.level--g
    color: $color-level-g

.level--zg
    color: $color-level-zg

.level--fill
    border-radius: $global-border-radius-rounded

    color: #fff

    &.level--o,
    &.level--io
        background-color: $color-level-o

    &.level--z
        background-color: $color-level-z

    &.level--v
        background-color: $color-level-v

    &.level--rv
        background-color: $color-level-rv

    &.level--g
        background-color: $color-level-g

    &.level--zg
        background-color: $color-level-zg

.level--outline
    border:
        radius: $global-border-radius-rounded
        width: 2px
        style: solid

    &.level--o
        border-color: $color-level-o

    &.level--z
        border-color: $color-level-z

    &.level--v
        border-color: $color-level-v

    &.level--rv
        border-color: $color-level-rv

    &.level--g
        border-color: $color-level-g

    &.level--zg
        border-color: $color-level-zg
</style>
