var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TheLayout',{staticClass:"layout--with-sidebar"},[_c('aside',{staticClass:"layout-side"},[_c('AppLayoutInner',[_c('AppSection',{attrs:{"variant":"side-menu"}},[_c('AppHeader',{staticClass:"section-header"},[_c('AppTitle',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"header__title"},[_vm._v(" Informatie ")])],1),_c('AppMenuSide',[_c('ul',{staticClass:"nav-menu__list"},[_c('AppMenuSideItem',[_c('AppMenuSideLink',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"to":{
                                    name: 'student_information_base',
                                    params: { id: _vm.studentId }
                                },"exact-active-class":"nav-side__link--active"}},[_vm._v(" Basis ")])],1),_c('AppMenuSideItem',[_c('AppMenuSideLink',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"to":{
                                    name: 'student_information_documents',
                                    params: { id: _vm.studentId }
                                },"exact-active-class":"nav-side__link--active"}},[_vm._v(" Documenten ")])],1),_c('AppMenuSideItem',[_c('AppMenuSideLink',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"to":{
                                    name: 'student_information_diagnosis',
                                    params: { id: _vm.studentId }
                                },"exact-active-class":"nav-side__link--active"}},[_vm._v(" Diagnose ")])],1),_c('AppMenuSideItem',[_c('AppMenuSideLink',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"to":{
                                    name: 'student_information_talents',
                                    params: { id: _vm.studentId }
                                },"exact-active-class":"nav-side__link--active"}},[_vm._v(" Talenten ")])],1)],1)])],1)],1)],1),_c('AppLayoutMain',[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }