<template>
    <section :class="antlerClass()">
        <slot />
    </section>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppSection",
    props: {
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("section", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.section--side-menu
    +mq-tablet-landscape
        padding-right: $spacing-regular

    .section-header
        margin-bottom: $spacing-large

.section--filter
    margin-right: $spacing-regular
</style>
